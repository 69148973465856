import axios from 'axios';
import { API_URL } from '../config/apiConnection';

export const getAllRentadoras = async() => {
    const response = await axios.get(`${API_URL}/rentadorasVehiculos/`)
    return response;
}

export const getRentadorasById = async(id) => {
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${API_URL}/rentadorasVehiculos/id/?id=${id}`, { headers });
    return response;
}

export const getRentadorasDifferent = async(nombre, skip, take) => {
    const response = await axios.get(`${API_URL}/rentadorasVehiculos/different`, {
        params:{
            nombre: nombre,
            skip: skip,
            take: take
        }
    });
    return response;
}

export const getRentadorasWithAllFilters = async(filtro, skip, take, accesabilidades) => {
    const response = await axios.get(`${API_URL}/rentadorasVehiculos/find/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
            accesabilidades: accesabilidades,
        }
    });
    return response;
}