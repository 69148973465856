import React, {Component} from 'react';

class EventoIndividualBanner extends Component {
    render(){
        var {Ptitle, imgName, Pdescription, PtitleIngles, PdescriptionIngles } = this.props;
        const language = localStorage.getItem('language')
        const ESPANOL = 'es';
        const getImage = ()=>{
            if(imgName==="defecto"){
                return require ('../../img/home/Chirripo1.jfif')
            }else{
                return imgName
            }
        }

        const getDescripcion = ()=>{
            if(language === ESPANOL){
                if(Pdescription?.length > 1){
                    return <p className="f_400 w_color f_size_16 l_height26">{Pdescription}</p>
                }
                return ''
            }
            if(PdescriptionIngles?.length > 1){
                return <p className="f_400 w_color f_size_16 l_height26">{PdescriptionIngles}</p>
            }
            return ''
        }

        return(
            <section className="sitio_single_banner_area">
                <div className="parallax-effect" style={{background: "linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url("+getImage()+")  center center/cover no-repeat"}}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sitio_single_banner_content">
                                <h2 className="wow fadeInUp" data-wow-delay="0.8s"><span></span>{language === ESPANOL ? Ptitle : PtitleIngles }<span></span></h2>
                                {
                                    getDescripcion()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default EventoIndividualBanner;