import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterData from '../components/Footer/FooterData';
import FooterRutas from "../components/Footer/FooterRutas";

const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error1.png")} alt="" />
          <h2>Error. No podemos encontrar la página que estás buscando</h2>
          {/* <p>
            Sentimos el inconveniente. Dirígete a la página de inicio y descubre todo lo que tiene Pérez Zeledón para ti{" "}
          </p> */}
          <br/>
          <a href="/" className="hosting_btn btn_hover">
            Ir a Página de Inicio <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <FooterRutas FooterData={FooterData} />
  </div>
);

export default NotFound;
