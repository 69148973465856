import React, {Component} from 'react';
import SeoTitle from '../Title/SeoTitle';
import OperadoresTuristicosCardSlider from './OperadoresTuristicosCardSlider';
import { useTranslation } from 'react-i18next';

const OperadoresTuristicosSectionTwo = ({operadores}) => {

    const { t } = useTranslation('global');

    return(
        <React.Fragment>
            <section className="case_studies_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t('tour_operators.more_tour_operators')} TitleP={ t('tour_operators.all_operators_are_waiting')}/>
                    <OperadoresTuristicosCardSlider operadores={operadores} />
                </div>
            </section>
        </React.Fragment>
        )
}

export default OperadoresTuristicosSectionTwo;