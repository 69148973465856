export const getTelefono = (contactos) => {
    if(contactos && contactos.length > 0){
        for(let i=0; i<contactos.length; i++){
            if(contactos[i].tipo === "Teléfono"){
                return "Tel: " + contactos[i].valor
            }
        }
    }
    return ""
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getFirtsImage(sitio){
    if(sitio.imagenes){
        if(sitio.imagenes.length > 0){
            return sitio.imagenes[0].url
        }else{
            return require ('../img/sinimagen.png')    
        }
    }else{
        return require ('../img/sinimagen.png')
    }
}

export function validateText(e, regex){
    var theEvent = e || window.event;
    if (regex.test(e.key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}

export function getHrefCall(tel){

    let telToCall = tel.trim().replace(" ", "").replace(/\D/g,'');
    return  `tel:+506${telToCall}`

}

export function getValueDistritos(distrito){
    const dis = mapDistritos.find(v=> v.key === distrito)
    return dis?.value
}


export const mapDistritos = [
    {
        key: "San Isidro De El General",
        value: "sanisidro"
    },
    {
        key: "El General",
        value: "general"
    },
    {
        key: "Daniel Flores",
        value: "danielflores"
    },
    {
        key: "Rivas",
        value: "rivas"
    },
    {
        key: "San Pedro",
        value: "sanpedro"
    },
    {
        key: "Platanares",
        value: "platanares"
    },
    {
        key: "Pejibaye",
        value: "pejibaye"
    },
    {
        key: "Cajon",
        value: "cajon"
    },
    {
        key: "Baru",
        value: "baru"
    },
    {
        key: "Rio Nuevo",
        value: "rionuevo"
    },
    {
        key: "Páramo",
        value: "paramo"
    },
    {
        key: "La Amistad",
        value: "laamistad"
    }

]


export const settingsSlider = (slidesToShow, slidesToShow1024, slidesToShow768, slidesToShow480)=>{
    return {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: slidesToShow1024,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: slidesToShow768,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: slidesToShow480,
                    slidesToScroll: 1
                }
            }
        ]
    }
} 
