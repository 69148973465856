import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../helpers/helpers';
import { useTranslation } from "react-i18next"

const HomeSearch =()=>{

    const [t, i18n] = useTranslation("global");
    
    const categorias = [
        {
            label: t('global.attractions'),
            value: 'atracciones'
        },
        {
            label: t('global.restaurants'),
            value: 'restaurantes'
        },
        {
            label: t('global.lodgings'),
            value: 'hospedaje'
        },
        {
            label: t('global.touristic_offices'),
            value: 'oficinas'
        },
        {
            label: t('global.tour_operators'),
            value: 'operadores'
        },
        {
            label: t('global.rental_cars'),
            value: 'rentacars'
        },
    ]

    const history = useHistory();
    const [ filtro, setFiltro ] = useState("")
    const [ categoria, setCategoria ] = useState("atracciones")

    

   

    const handleFilter = (e) => {
        let f = "Todos"
        // let c = "Todos"
        
        if(filtro.length > 1){
            f = filtro
        }
        // if(categoria.length > 1){
        //     c = categoria
        // }
        history.push(`/buscar/${categoria}/${f}/Todos/1/Todos/Todos`);
        
    }

    return(
        <section className="atracciones-search_search_area mt_40 mb_40">
            <div className="container">
                <div className="atracciones-search_box_info">
                    <h3 className="wow fadeInUp" data-wow-delay="0.3s">{ t('home.what_are_you_looking_for') }</h3>
                    <form>
                        <div className="atracciones-search_form_inner">
                            <input type="text" className="form-control" placeholder= {t('home.write_here')} value={filtro} onChange={(e)=>setFiltro(e.target.value)} onKeyDown={(e) => {if(e.key === "Enter"){handleFilter(e)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} maxLength="60" />
                            <div className="atracciones-search_select">
                                <select className="form-control selectpickers" value={categoria} onChange={(e)=>setCategoria(e.target.value)}>
                                    {
                                        categorias.map((c, i)=>{
                                            return <option key={i} value={c.value}>{c.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <button onClick={handleFilter} className="hosting_btn">{t('home.search')}</button>
                        </div>
                    </form>
                    {/* <ul className="list-unstyled atracciones-search_price">
                        <li>.com <span>$6.50</span></li>
                        <li>.sg <span>$10</span></li>
                        <li>.info <span>$11</span></li>
                        <li>.co <span>$9.50</span></li>
                        <li>.net <span>$7.50</span></li>
                    </ul> */}
                </div>
            </div>
        </section>
    )
}

export default HomeSearch;