import React, { Component } from "react";
import { getValueDistritos } from "../../helpers/helpers";
import { useTranslation } from "react-i18next"

const EventosCardItem  = ({id, img, evento, distrito }) => {
    const { t } = useTranslation("global");
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    const getDate = () => {
        if(evento.fechaInicio === evento.fechaFin){
            let fechaSlice = evento.fechaInicio.slice(0,10)
            let fechaSplit = fechaSlice.split('-')
            return ` ${fechaSplit[2]}/${fechaSplit[1]}/${fechaSplit[0]}` 
        }
        let fechaInicioSlice = evento.fechaInicio.slice(0,10)
        let fechaInicioSplit = fechaInicioSlice.split('-')
        let fechaFinSlice = evento.fechaFin.slice(0,10)
        let fechaFinSplit = fechaFinSlice.split('-')
        return ` ${fechaInicioSplit[2]}/${fechaInicioSplit[1]}/${fechaInicioSplit[0]} - ${fechaFinSplit[2]}/${fechaFinSplit[1]}/${fechaFinSplit[0]}` 
    }

    return(
        <div className="col-sm-6">
            <div className="h_blog_item">
                <a href={`/eventos/evento/${id}`}><img src={img} alt="blog"/></a>
                <div className="h_blog_content">
                    <span className="post_time"><i className="icon_pin_alt"></i>{distrito}</span>
                    <a href={`/evento/${id}`}><h3>{language === ESPANOL ? evento.nombre : evento.nombreIngles}</h3></a>
                    <div className="post-info-bottom">
                        <span className="post_time">
                            {language === ESPANOL ? evento.descripcion : evento.descripcionIngles}
                        </span>
                    </div>
                    {
                        evento.fechaInicio &&
                        <div>
                            <span className="post_time" style={{fontWeight: 'bold'}}>{language === ESPANOL ? 'Fecha: ' : 'Date: '}</span>
                            <span>{getDate()}</span>
                        </div>
                    }
                    <div className="post-info-bottom">
                        <a href={`/evento/${id}`} className="learn_btn_two">{ t('events.see_event') }<i className="arrow_right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
export default EventosCardItem;