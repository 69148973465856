import React from 'react';
import { useTranslation } from "react-i18next"

const AtraccionesActionTwo =()=> {
    const { t } = useTranslation("global");
    
    return(
        <section className="erp_action_area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4">
                        <img src={require("../../img/atracciones/sabiasque.png")} alt=""/>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="erp_content">
                            <h2>{ t("attractions.did_you_know_that") }</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AtraccionesActionTwo;