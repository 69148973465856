import React from 'react';
import Fade from 'react-reveal/Fade';
import { getTelefono } from '../../helpers/helpers';
import SeoTitle from '../Title/SeoTitle';
import { useTranslation } from 'react-i18next';

const RentaCarsSectionOne = ({rentadoras}) => {

    const { t } = useTranslation('global');

    const getDetailsDistritoWithTelephone = (sitio) => {
        if(sitio.ubicacion && sitio.ubicacion.distrito){
            return (<p>{`${sitio.ubicacion?.distrito}, ${sitio.ubicacion?.detalle}`}<br/>{getTelefono(sitio.contactos)}</p>)
        }else{
            return (<p>{getTelefono(sitio.contactos)}</p>)
        }
    }


    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t('car_rentals.some_of_the_car_rental') }/>
                    <div className="row seo_service_info">
                        {
                            rentadoras.map((r, i) => {
                                return (
                                    <Fade bottom duration={500}>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="restaurante_item">
                                                <img src={r.imagenes[0]?.url} alt=""/>
                                                <div className='text_box'>
                                                    <a href={`/sitio/rentacars/${r.id}`}>
                                                        <h4>{r.nombre}</h4>
                                                    </a>
                                                    {
                                                        getDetailsDistritoWithTelephone(r)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })
                        }
                        <div className="col-lg-12 text-center mt_40">
                            <a href="/buscar/rentacars/Todos/Todos/1/Todos/Todos" className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default RentaCarsSectionOne;
