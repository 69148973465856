import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import HospedajeCardSlider from './HospedajeCardSlider';
import { useTranslation } from 'react-i18next';

const HospedajeSectionTwo = ({ hoteles } ) => {
    
    const { t } = useTranslation('global');

    return(
        <React.Fragment>
            <section className="case_studies_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t('lodging.more_lodging_options') } TitleP={ t('lodging.find_the_ideal_place_for_you') }/>
                    <HospedajeCardSlider hoteles={hoteles} />
                </div>
            </section>
        </React.Fragment>
    )
}

export default HospedajeSectionTwo;