import React from 'react';
import Fade from 'react-reveal/Fade';
import { getTelefono } from '../../helpers/helpers';
import SeoTitle from '../Title/SeoTitle';
import { useTranslation } from 'react-i18next';

const OperadoresTuristicosSectionOne = ({operadores}) => {

    const { t } = useTranslation('global');

    const getDetailsDistritoWithTelephone = (sitio) => {
        if(sitio.ubicacion && sitio.ubicacion.distrito){
            return (<p>{`${sitio.ubicacion?.distrito}, ${sitio.ubicacion?.detalle}`}<br/>{getTelefono(sitio.contactos)}</p>)
        }else{
            return (<p>{getTelefono(sitio.contactos)}</p>)
        }
    }
    
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t('tour_operators.just_what_you_need') } TitleP={ t('tour_operators.find_the_tour_operator') }/>
                    <div className="row seo_service_info">
                        {
                            operadores.map((o, i)=>{
                                return (
                                    <Fade bottom duration={500} key={i}>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="restaurante_item">
                                                <img src={o.imagenes[0].url} alt=""/>
                                                <div className='text_box'>
                                                    <a href={`/sitio/operadoresTuristicos/${o.id}`}>
                                                        <h4>{o.nombre}</h4>
                                                    </a>
                                                    {
                                                        getDetailsDistritoWithTelephone(o)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })
                        }
                        <div className="col-lg-12 text-center mt_40">
                            <a href="/buscar/operadores/Todos/Todos/1/Todos/Todos"className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default OperadoresTuristicosSectionOne;
