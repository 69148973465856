import React, { useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import Contactos from '../components/Contactos';
import FooterRutas from '../components/Footer/FooterRutas';
import SitioIndividualBanner from '../components/Banner/SitioIndividualBanner';
import { useTranslation } from 'react-i18next';

const Contacto = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    
const { t } = useTranslation('global');

    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <SitioIndividualBanner Ptitle={ t('contact.contact_title') } Pdescription={ t('contact.contact_description') }  imgName="defecto" />
            <Contactos />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Contacto;