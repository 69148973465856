import React, { useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import SitioIndividualBanner from '../components/Banner/SitioIndividualBanner';
import SearchPageContainer from '../components/SearchPage/SearchPageContainer';
import { useParams } from 'react-router-dom';
import FooterRutas from '../components/Footer/FooterRutas';
import { useTranslation } from 'react-i18next';

const SearchPage = () => {
    let { tipo, filtro, categorias, pagina, accesabilitiesInPath, othersInPath} = useParams()
    const { t } = useTranslation('global');

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <SitioIndividualBanner breadcrumbClass="breadcrumb_area" imgName="defecto" Ptitle={ t('browser.browser') } Pdescription={ t('browser.browser_description') }/>
            <SearchPageContainer tipoP={tipo} filtroP={filtro} categoriasP={categorias} pagina={pagina} accesabilitiesInPath={accesabilitiesInPath} othersInPath={othersInPath} />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default SearchPage;