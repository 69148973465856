import React from 'react'
import Reveal from 'react-reveal/Reveal';

export const PerezZeledonItems = (props) => {

    var {rowClass, fimage, iImg, ftitle, descriptions, aClass} = props;

    return(
        <div className={`agency_featured_item ${rowClass} `}>
            <div className="col-lg-6">
               <Reveal effect="fadeInLeft">
                    <div className="agency_featured_img text-center">
                        <img src={require ("../../img/distritos/" + fimage)} alt=""/>
                    </div>
               </Reveal>
            </div>
            <div className="col-lg-6">
                <div className={`agency_featured_content wow fadeInLeft ${aClass}`} data-wow-delay="0.6s">
                    <Reveal effect="fadeInRight">
                        <img className="number" src={require ("../../img/resources/" + iImg)} alt=""/>
                        <h3>{ftitle}</h3>
                        <p className='text-justify'>{descriptions}</p>
                    </Reveal>
                </div>
            </div>
        </div>
    )
}


export default PerezZeledonItems;