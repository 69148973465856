import React from 'react';
import Fade from 'react-reveal/Fade';
import { getTelefono } from '../../helpers/helpers';
import SeoTitle from '../Title/SeoTitle';
import { useTranslation } from 'react-i18next';

const RestaurantesSectionOne = ({restaurantes}) => {
    const { t } = useTranslation('global');

    const getDetails = (sitio) => {
        if(sitio.ubicacion && sitio.ubicacion.distrito){
            return (<p>{`${sitio.ubicacion?.distrito}, ${sitio.ubicacion?.detalle}`}<br/>{getTelefono(sitio.contactos)}</p>)
        }else{
            return (<p>{getTelefono(sitio.contactos)}</p>)
        }
    }
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div className="container">
                    <SeoTitle Title= { t('restaurants.restaurants_you_need_to_visit') } TitleP={ t('restaurants.here_are_some_of_them') }/>
                    <div className="row seo_service_info">
                        {
                            restaurantes.map((r, i)=>{
                                return (
                                    <Fade bottom duration={700} key={i}>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="restaurante_item">
                                                <img src={r.imagenes[0].url} alt=""/>
                                                <div className='text_box'>
                                                    <a href={`/sitio/restaurantes/${r.id}`}>
                                                        <h4>{r.nombre}</h4>
                                                    </a>
                                                    {
                                                        getDetails(r)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            })
                        }
                        <div className="col-lg-12 text-center mt_40">
                            <a href="/buscar/restaurantes/Todos/Todos/1/Todos/Todos" className="restaurante_btn restaurante_btn_one btn_hover">{ t('restaurants.see_more') }</a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default RestaurantesSectionOne;
