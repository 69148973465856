import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import { useParams } from 'react-router-dom';
import FooterData from '../components/Footer/FooterData';
import PerezZeledonBanner from '../components/PerezZeledon/PerezZeledonBanner';
import { distritos } from '../services/distritos';
import DistritoContainer from '../components/distritos/DistritoContainer';
import FooterRutas from '../components/Footer/FooterRutas';

const Distrito = () => {
    let { distrito } = useParams()

    const [ dis, setDistrito ] = useState(null)

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])


    useEffect(()=>{
        let d = distritos.find(e => e.id === distrito)
        setDistrito(d)
    }, [ distrito ])


    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <PerezZeledonBanner titulo={dis?.nombre} descripcion=""/>
            <DistritoContainer distrito={dis} />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Distrito;