import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import RentaCarsBanner from '../components/Banner/RentaCarsBanner';
import RentaCarsSectionOne from '../components/RentaCars/RentaCarsSectionOne';

import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { getAllRentadoras } from '../services/rentadoras';

const RentaCars = () => {

    const [ rentadoras, setRentadoras ] = useState([])

    useEffect(()=>{
        getRentadoras()
        window.scrollTo(0, 0)
    
        return ()=>{
            setRentadoras([])
        }
    },[])

    const getRentadoras = ()=>{
        getAllRentadoras().then(
            res => {
                if(res.data){
                    setRentadoras(res.data)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0"/>
            <RentaCarsBanner />
            <RentaCarsSectionOne rentadoras={rentadoras} />
            <AtraccionesActionThree />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default RentaCars;