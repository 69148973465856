import axios from 'axios';
import { API_URL } from '../config/apiConnection';


export const sendMessage = async(nombre, correo, asunto, mensaje) => {
    const men = { nombre: nombre, correo: correo, asunto: asunto, mensaje: mensaje, estado: 'Sin responder', state: 'Active' };
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.post(`${API_URL}/mensajes/`, men, { headers });
    return response;
}