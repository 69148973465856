import React, {Component} from 'react';
import SeoTitle from '../Title/SeoTitle';
import RestaurantesCardSlider from './RestaurantesCardSlider';
import { useTranslation } from 'react-i18next';

const RestaurantesSectionTwo = ({ restaurantes }) => {

    const { t } = useTranslation('global');

    return(
        <React.Fragment>
            <section className="case_studies_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t('restaurants.additional_restaurants') } TitleP={ t('restaurants.do_not_miss_the_opportunity') }/>
                    <RestaurantesCardSlider restaurantes={restaurantes} />
                </div>
            </section>
        </React.Fragment>
    )
    
}

export default RestaurantesSectionTwo;