import React from 'react';
import { useTranslation } from "react-i18next"

const Contactanos =()=> {

    const [t, i18n] = useTranslation("global");

    return(
        <section className="erp_action_area mt_130 mb_70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4">
                        <img src={require("../../img/resources/contactanos.png")} alt=""/>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="erp_content">
                            <h2>{ t('home.contact_us_description') }</h2>
                        </div>
                        <a href="/contacto" className="hosting_btn btn_hover">{ t('home.contact_us') }</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Contactanos;