import React from 'react'

export const DistritoItem = (props) => {

    var { distritoImage, nombre, href } = props;

    return(
        <div className="distritos_item">
            <img src={require ("../../img/distritos/" + distritoImage)} alt="team"/>
            <div className="team_content">
                <a href=".#">
                    <h3 className="f_p f_size_16 f_600 t_color3">{nombre}</h3>
                </a>
            </div>
            <div className="hover_content">
                <div className="n_hover_content">
                    <a href={`/distritos/${href}`}>
                        <h3 className="f_p f_size_16 f_600 w_color">{nombre}</h3>
                    </a>
                </div>
            </div>
        </div>
    )
}
