import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { lowerFirstLetter, getFirtsImage, getValueDistritos, validateText } from '../../helpers/helpers';
import { getAllCount, getAtraccionesWithSameCategory } from '../../services/atracciones';
import { getHotelesWithSameCategory } from '../../services/hospedaje';
import { getOperadoresWithSameCategory } from '../../services/operadoresTuristicos';
import { getRestaurantesWithSameCategory } from '../../services/restaurantes';
import { useTranslation } from 'react-i18next';

export const StitioIndividualSideBar = (props) => {

    let { tipo, nombresR, categoriasR, sitiosS } = props

    const [ counts, setCounts ] = useState()
    const [ masSitios, setMasSitios ] = useState([])
    const [ tipoBuscar, setTipoBuscar ] = useState("")
    const [ filtro, setFiltro ] = useState("")
    const history = useHistory();
    const { t } = useTranslation('global');

    useEffect(()=>{
        getCount()
    },[])

    useEffect(()=>{
        if(nombresR.length>0){
            
            if(tipo === "Atracciones"){
                getAtraccionesWithSameCategory(nombresR, 0, 3, categoriasR).then(
                    res =>{
                        if(res.data){
                            setMasSitios(res.data.atracciones)
                        }
                })
            }else if(tipo === "Hospedaje"){
                getHotelesWithSameCategory(nombresR, 0, 3, categoriasR).then(
                    res =>{
                        if(res.data){
                            setMasSitios(res.data.hoteles)
                        }
                })
            }else if(tipo === "Restaurantes"){
                getRestaurantesWithSameCategory(nombresR, 0, 3, categoriasR).then(
                    res =>{
                        if(res.data){
                            setMasSitios(res.data.restaurantes)
                        }
                })
            }else if(tipo==="Operadores Turísticos"){
                getOperadoresWithSameCategory(nombresR, 0, 3, categoriasR).then(
                    res =>{
                        if(res.data){
                            setMasSitios(res.data.operadores)
                        }
                })
            }else{
                setMasSitios(sitiosS)
            }
        }

        setTipoBuscar(tipoToTipoBuscar(tipo))    
    },[nombresR, categoriasR, sitiosS, tipo])

    const getCount = ()=>{
        
        getAllCount().then(
            res => {
                if(res.data){
                    setCounts(res.data)
                }
            }
        )
    };

    const getUbicacion = (s)=>{
        if(s.ubicacion){
            if(s.ubicacion.distrito){
                return (
                    <div className="entry_post_info">
                        <a href={`/distritos/${getValueDistritos(s.ubicacion.distrito)}`}>{s?.ubicacion?.distrito || ""}</a>
                    </div>
                )
            }
        }
    }

    const handleSearch = (e) => {
        let f = "Todos"
        
        if(filtro.length > 1){
            f = filtro
        }
        history.push(`/buscar/${tipoBuscar}/${f}/Todos/1/Todos/Todos`);
        
    }

    const getClassCategoriaBuscar = (c) => {
        
        if(c === tipo){
            return "categorie_active"
        }
        return ""
    }

    const tipoToTipoBuscar = (c) => {
        if(c === "Atracciones"){
            return "atracciones"
        }else if(c === "Hospedaje"){
            return "hospedaje"
        }else if(c === "Restaurantes"){
            return "restaurantes"
        }else if(c==="Operadores Turísticos"){
            return "operadores"
        }else if(c === "Oficinas Turísticas"){
            return "oficinas"
        }else{
            return "rentacars"
        }
    }

    const handleCategoriaBuscar = (e, c) => {
        if(c !== tipo){
            history.push(`/buscar/${tipoToTipoBuscar(c)}/Todos/Todos/1/Todos/Todos`);
        }
    }

    const getUrl = (s) => {
        let t=""
        
        if(tipo === "Atracciones"){
            t = "atracciones"
        }else if(tipo === "Hospedaje"){
            t = "hospedaje"
        }else if(tipo === "Restaurantes"){
            t = "restaurantes"
        }else if(tipo==="Operadores Turísticos"){
            t = "operadoresTuristicos"
        }else if(tipo === "Oficinas Turísticas"){
            t = "oficinasTuristicas"
        }else{
            t = "rentacars"
        }

        return "/sitio/"+t+"/"+s.id
    }

    const getTipoTraducido = (tipo) => {
        let nombre = '';
      
        if (tipo === "Atracciones") {
          nombre = t('global.attractions');
        } else if (tipo === "Hospedaje") {
          nombre = t('global.lodgings');
        } else if (tipo === "Restaurantes") {
          nombre = t('global.restaurants');
        } else if (tipo === "Operadores Turísticos") {
          nombre = t('global.tour_operators');
        } else if (tipo === "Oficinas Turísticas") {
          nombre = t('global.touristic_offices');
        } else {
          nombre = t('global.rental_cars');
        }
      
        return lowerFirstLetter(nombre);
      }

    return (
        <div className="blog-sidebar mt_30">
            <div className="widget sidebar_widget widget_search">
                <div className="widget_title">
                    <h3 className="f_p f_size_20 t_color3">{ t('global.keep_looking') }</h3>
                </div>
                <form className="search-form input-group">
                    <input value={filtro} onKeyDown={(e) => {if(e.key === "Enter"){handleSearch(e)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} onChange={(e)=>setFiltro(e.target.value)} type="search" className="form-control widget_input" placeholder={ t('global.search') + ' ' + getTipoTraducido(tipo)} maxLength="40"/>
                    <button type="button" onClick={handleSearch} ><i className="ti-search"></i></button>
                </form>
            </div>
            {
                masSitios.length > 0 && (
                    <div className="widget sidebar_widget widget_recent_post mt_60">
                        <div className="widget_title">
                            <h3 className="f_p f_size_20 t_color3">{ t('global.similar') }</h3>
                            <div className="border_bottom"></div>
                        </div>
                        {
                            masSitios.map((s, i)=>{
                                return(
                                    <div className="media post_item" key={i}>
                                        <img src={getFirtsImage(s)} alt=""/>
                                        <div className="media-body">
                                            <a href={getUrl(s)}>
                                                <h3 className="f_size_16 f_p f_400">{s?.nombre}</h3>
                                            </a>
                                            {
                                                getUbicacion(s)
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            
            <div className="widget sidebar_widget widget_categorie mt_60">
                <div className="widget_title">
                    <h3 className="f_p f_size_20 t_color3">{ t('global.categories') }</h3>
                    <div className="border_bottom"></div>
                </div>
                <ul className="list-unstyled">
                    <li><span className={`${getClassCategoriaBuscar("Atracciones")}`} onClick={(e)=>handleCategoriaBuscar(e, "Atracciones")}>{ t('global.attractions') }</span><em>({counts?.atracciones[0].count})</em></li>
                    <li><span className={`${getClassCategoriaBuscar("Hospedaje")}`} onClick={(e)=>handleCategoriaBuscar(e, "Hospedaje")}>{ t('global.lodgings') }</span><em>({counts?.hoteles[0].count})</em></li>
                    <li><span className={`${getClassCategoriaBuscar("Restaurantes")}`} onClick={(e)=>handleCategoriaBuscar(e, "Restaurantes")}>{ t('global.restaurants') }</span><em>({counts?.restaurantes[0].count})</em></li>
                    <li><span className={`${getClassCategoriaBuscar("Operadores Turísticos")}`} onClick={(e)=>handleCategoriaBuscar(e, "Operadores Turísticos")}>{ t('global.tour_operators') }</span><em>({counts?.operadores[0].count})</em></li>
                    <li><span className={`${getClassCategoriaBuscar("Oficinas Turísticas")}`} onClick={(e)=>handleCategoriaBuscar(e, "Oficinas Turísticas")}>{ t('global.touristic_offices') }</span><em>({counts?.oficinas[0].count})</em></li>
                    <li><span className={`${getClassCategoriaBuscar("Rent a Cars")}`} onClick={(e)=>handleCategoriaBuscar(e, "Rent a Cars")}>{ t('global.rental_cars') }</span><em>({counts?.rentacars[0].count})</em></li>
                </ul>
            </div>
        </div>
    )
}

export default StitioIndividualSideBar;