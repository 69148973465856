function getYearNow() {
  return new Date().getFullYear();
}

const FooterData = {
  CompanyWidget: [
    {
      id: 1,
      title: "Get In Touch",
      description: "Don’t miss any updates of our new templates and extensions.!",
    }
  ],
  AboutWidget: [
    {
      id: 1,
      title: "Secciones",
      titleEN: "Sections",
      menuItems: [
        {
          id: 1,
          url: '/',
          text: 'Inicio',
          textEN: 'Home',
        },
        {
          id: 2,
          url: '/atracciones',
          text: 'Atracciones',
          textEN: 'Attractions',
        },
        {
          id: 3,
          url: '/restaurantes',
          text: 'Restaurantes',
          textEN: 'Restaurants',
        },
        {
          id: 4,
          url: '/hospedaje',
          text: 'Hospedaje',
          textEN: 'Lodging',
        },
        {
          id: 5,
          url: '/oficinas-turisticas',
          text: 'Oficinas turísticas',
          textEN: 'Touristic offices',
        },
        {
          id: 6,
          url: '/operadores-turisticos',
          text: 'Operadores turísticos',
          textEN: 'Tour operators',
        },
        {
          id: 7,
          url: '/renta-cars',
          text: 'Renta Cars',
          textEN: 'Rental car',
        },
        {
          id: 8,
          url: '/contacto',
          text: 'Contacto',
          textEN: 'Contact',
        },
      ],
    },
    {
      id: 2,
      title: "Acerca de",
      titleEN: "About",
      menuItems: [
        {
          id: 1,
          text: 'Pérez Zeledón',
          textEN: 'Pérez Zeledón',
          url: '/perezzeledon',
        },
        {
          id: 2,
          text: 'Mapas',
          textEN: 'Maps',
          url: '/mapas',
        },
        {
          id: 3,
          text: "San Isidro De El General",
          textEN: "San Isidro De El General",
          url: "/distritos/sanisidro",
        },
        {
          id: 4,
          text: "El General",
          textEN: "El General",
          url: "/distritos/general",
        },
        {
          id: 5,
          text: "Daniel Flores",
          textEN: "Daniel Flores",
          url: "/distritos/danielflores",
        },
        {
          id: 6,
          text: "Rivas",
          textEN: "Rivas",
          url: "/distritos/rivas",
        },
        {
          id: 7,
          text: "San Pedro",
          textEN: "San Pedro",
          url: "/distritos/sanpedro",
        },
      ],
    },
    {
      id: 3,
      title: " ",
      menuItems: [
        {
          id: 1,
          text: "Platanares",
          textEN: "Platanares",
          url: "/distritos/platanares"
        },
        {
          id: 2,
          text: "Pejibaye",
          textEN: "Pejibaye",
          url: "/distritos/pejibaye"
        },
        {
          id: 3,
          text: "Cajón",
          textEN: "Cajón",
          url: "/distritos/cajon"
        },
        {
          id: 4,
          text: "Barú",
          textEN: "Barú",
          url: "/distritos/baru"
        },
        {
          id: 5,
          text: "Río Nuevo",
          textEN: "Río Nuevo",
          url: "/distritos/rionuevo"
        },
        {
          id: 6,
          text: "Páramo",
          textEN: "Páramo",
          url: "/distritos/paramo"
        },
        {
          id: 7,
          text: "La Amistad",
          textEN: "La Amistad",
          url: "/distritos/laamistad"
        }
      ]
    }
  ],
  termsCondition: [
    {
      id: 1,
      title: "Contáctanos",
      titleEN: "Contact us",
      menuItems: [
        {
          id: 1,
          url: 'contacto',
          text: 'Quiero aparecer en la página',
        },
        {
          id: 1,
          url: 'mailto:erick.madrigal.villanueva@una.ac.cr',
          text: 'erick.madrigal.villanueva@una.ac.cr',
        },
        {
          id: 2,
          url: 'tel:88388535',
          text: '88388535',
        },
      ],
    }
  ],
  socialIcon: [
    {
      id: 1,
      url: 'https://www.facebook.com/rutasturisticaspz',
      icon: 'ti-facebook',
    },
    {
      id: 2,
      url: 'https://www.instagram.com/rutasturisticaspz',
      icon: 'ti-instagram',
    }
  ],
  copywrite: `quehacerenperez.com © ${getYearNow()}. Todos los derechos reservados.`,
  copywriteEN: `quehacerenperez.com © ${getYearNow()}. All rights reserved.`,
};
export default FooterData;