import React from 'react'
import Fade from 'react-reveal/Fade';
import { useTranslation } from "react-i18next"

export const VeniAPerez = () => {

    const [t, i18n] = useTranslation("global");

    return (
        <section className="seo_features_one sec_pad" >
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-6">
                        <Fade bottom cascade>
                            <div className="seo_features_content">
                                <h2>{t('home.come_to_perez_campaign')}</h2>
                                <p className='text-justify'>
                                    <strong>{t('home.come_to_perez')}</strong>
                                    {t('home.come_to_perez_description')}
                                    <strong>{t('home.come_to_perez_description_more')}</strong>
                                    #VeníAPérez
                                </p>
                                <a href="https://www.facebook.com/Ven%C3%AD-a-P%C3%A9rez-275542344247300" style={{ alignItems: "center" }} target="_blank" rel='noopener noreferrer' className="mapa_item_btn"><i className="ti-facebook"></i>{t('home.visit')}</a>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-lg-6">
                        <div className="seo_features_img">
                            <img src={require("../../img/home/veniaperez.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
