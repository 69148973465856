import React, { useState, useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import HospedajeBanner from '../components/Banner/HospedajeBanner';
import HospedajeSectionOne from '../components/Hospedaje/HospedajeSectionOne';
import HospedajeSearch from '../components/Hospedaje/HospedajeSearch';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import HospedajeSectionTwo from '../components/Hospedaje/HospedajeSectionTwo';
import { getHotelesWithImage } from '../services/hospedaje';

const Hospedaje = () => {

    const [ hoteles, setHoteles ] = useState([])

    useEffect(()=>{
        window.scrollTo(0, 0)
        getHoteles()

        return ()=>{
            setHoteles([])
        }
    },[])

    const getHoteles = ()=>{
        getHotelesWithImage(12).then(
            res => {
                if(res.data.hoteles){
                    setHoteles(res.data.hoteles)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar  cClass="custom_container p0"/>
            <HospedajeBanner />
            <HospedajeSectionOne hoteles={hoteles.slice(0, 3)} />
            <HospedajeSearch />
            <HospedajeSectionTwo hoteles={hoteles.slice(3, 12)} FooterData={FooterData}/>
            <AtraccionesActionThree/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Hospedaje;