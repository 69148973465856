import React, { useEffect, useState } from 'react';
import { getAllCount } from '../../services/atracciones';
import { useTranslation } from 'react-i18next';

const Cantidades = () => {
    const [counts, setCounts] = useState()
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        getCount()
    }, [])

    const getCount = () => {

        getAllCount().then(
            res => {
                if (res.data) {
                    setCounts(res.data)
                }
            }
        )
    };

    return (
        <div id="countdown" className="row">

            <div className="col-lg-2">
                <div className="box one">
                    <a href="/atracciones" style={{ cursor: "pointer" }}>
                        <div className="text">
                            <p id="day">{counts?.atracciones[0].count}</p>
                            <span className="text">{ t('global.attractions') }</span>
                        </div>
                    </a>
                </div>
            </div>

            <div className="col-lg-2">
                <div className="box five">
                    <a href="/hospedaje" style={{ cursor: "pointer" }}>
                        <div className="text">
                            <p id="hour">{counts?.hoteles[0].count}</p>
                            <span className="text">{ t('global.lodgings') }</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="box two">
                    <a href="/restaurantes" style={{ cursor: "pointer" }}>
                        <div className="text">
                            <p id="minute">{counts?.restaurantes[0].count}</p>
                            <span className="text">{ t('global.restaurants') }</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="box six">
                    <a href="/operadores-turisticos" style={{ cursor: "pointer" }}>
                        <div className="text cantidades_largo">
                            <p id="second">{counts?.operadores[0].count}</p>
                            <span className="text">{ t('global.tour_operators') }</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="box three">
                    <a href="/oficinas-turisticas" style={{ cursor: "pointer" }}>
                        <div className="text cantidades_largo">
                            <p id="second">{counts?.oficinas[0].count}</p>
                            <span className="text">{ t('global.touristic_offices') }</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className="col-lg-2">
                <div className="box four">
                    <a href="/renta-cars" style={{ cursor: "pointer" }}>
                        <div className="text">
                            <p id="second">{counts?.rentacars[0].count}</p>
                            <span className="text">{ t('global.rental_cars') }</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Cantidades;