import axios from 'axios';
import { API_URL } from '../config/apiConnection';



export const getAtraccionesWithImage = async(take) => {
    const response = await axios.get(`${API_URL}/atracciones/haveimages`, {
        params:{
            take: take,
        }
    });
    return response;
}

export const getAtraccionesById = async(id) => {
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${API_URL}/atracciones/id/?id=${id}`, { headers });
    return response;
}

export const getAtraccionesWithUbicacion = async(nombre, skip, take, provincia, canton, distrito) => {
    const response = await axios.get(`${API_URL}/atracciones/find/ubicacion`, {
        params:{
            nombre: nombre,
            skip: skip,
            take: take,
            provincia: provincia,
            canton: canton,
            distrito: distrito,
        }
    });
    return response;
}

export const getAtraccionesWithSameCategory = async(nombres, skip, take, categorias) => {
    const response = await axios.get(`${API_URL}/atracciones/find/same/category`, {
        params:{
            nombres: nombres,
            skip: skip,
            take: take,
            categorias: categorias,
        }
    });
    return response;
}

export const getAtraccionesWithAllFilters = async(filtro, skip, take, categorias, accesabilidades, otros) => {
    const response = await axios.get(`${API_URL}/atracciones/find/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
            categorias: categorias,
            accesabilidades: accesabilidades,
            otros: otros
        }
    });
    return response;
}

export const getAllWithAllFilters = async(filtro, skip, take) => {
    const response = await axios.get(`${API_URL}/atracciones/find/all/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
        }
    });
    return response;
}

export const getAllCategorias = async() => {
    const response = await axios.get(`${API_URL}/categorias/`);
    return response;
}

export const getAllCount = async() => {
    const response = await axios.get(`${API_URL}/atracciones/count/all`);
    return response;
}

