import React, { Component } from "react";
import { getValueDistritos } from "../../helpers/helpers";
import { useTranslation } from "react-i18next"

const AtraccionesCardItemTwo  = ({id, img, nombre, distrito }) => {
    const { t } = useTranslation("global");

    return(
        <div className="col-sm-6">
            <div className="h_blog_item">
                <a href={`/sitio/atracciones/${id}`}><img src={img} alt="blog"/></a>
                <div className="h_blog_content">
                    <a href={`/distritos/${getValueDistritos(distrito)}`} className="post_time"><i className="icon_pin_alt"></i>{distrito}</a>
                    <a href={`/sitio/atracciones/${id}`}><h3>{nombre}</h3></a>
                    <div className="post-info-bottom">
                        <a href={`/sitio/atracciones/${id}`} className="learn_btn_two">{ t('attractions.see_attraction') }<i className="arrow_right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
export default AtraccionesCardItemTwo;