import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import Confetti from 'react-confetti'

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {

  const [width, setWidth ] = useState()
  const [ height, setHeight] = useState()

  useEffect(()=>{
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  },[])
  return (
    <>
      <section className="timer-container" style={{background: "linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(" + (require("../img/home/Chirripo1.jfif")) + ")  center center/cover no-repeat"}}>
        <section className="timer">
            
            {
              timerDays === 0 && timerHours === 0 && timerMinutes === 0 && timerSeconds === 0 ?
                <>
                  <Confetti
                    width={width}
                    height={height}
                  />
                  <div className="title_clock">
                        <span>¡BIENVENIDOS!</span>
                  </div>
                  <div className="final_countdown_ready">
                      <h2>La espera ha terminado, es momento de conocer</h2>
                      <h2 id="quehacerenperez_title">QUÉ HACER EN PÉREZ</h2>
                      
                      <Link to="/">
                        <button>
                          COMENZAR
                        </button>
                      </Link>
                  </div>
                </>
              :
                <>
                  <div className="title_clock">
                      <span>¿Qué hacer en Pérez?</span>
                  </div>
                  <div className="clock">
                      <section>
                          <p>{timerDays < 10 && "0"}{timerDays}</p>
                          <small>Días</small>
                      </section>
                      <span>:</span>
                      <section>
                          <p>{timerHours < 10 && "0"}{timerHours}</p>
                          <small>Horas</small>
                      </section>{" "}
                      <span>:</span>
                      <section>
                          <p>{timerMinutes < 10 && "0"}{timerMinutes}</p>
                          <small>Minutos</small>
                      </section>{" "}
                      <span>:</span>
                      <section>
                          <p>{timerSeconds < 10 && "0"}{timerSeconds}</p>
                          <small>Segundos</small>
                      </section>
                  </div> 
                </>
            }
            
            <div className="logos_clock">
                <img src={require("../img/logort1.1.png")} alt="logo" />
            </div>
            <div className="creditos_countdown">
            <p>Desarrollado por: <a href='https://www.linkedin.com/in/pablove00/' target="_blank" rel='noopener noreferrer'>Pablo Venegas</a> y <a href='https://www.linkedin.com/in/luis-miguel-valverde-navarro-b1816b213/' target="_blank" rel='noopener noreferrer'>Luis Valverde</a></p>
            </div>
        </section>
        
      </section>
    </>
  );
};

Clock.defaultProps = {
  timerDays: 10,
  timerHours: 10,
  timerMinutes: 10,
  timerSeconds: 10,
};

export default Clock;