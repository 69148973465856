import React from 'react'
import Slider from 'react-slick';
import { dataImagenesHome } from '../../helpers/dataImagenesHome';
import { settingsSlider } from '../../helpers/helpers';
import Sectitle from '../Title/Sectitle';
import { useTranslation } from "react-i18next"

export const HomeAtraccionesImagenes = () => {
  
  const [t, i18n] = useTranslation("global");

  const haveSlider = () => {

        return (
          <div  className="container">

          <Sectitle sClass="sec_title text-center mb_15" Title={ t('home.some_pictures') } tClass="t_color3 mb_20" />
          <div className="row">
            <div className="col-lg-12">
              <React.Fragment>
                <Slider {...settingsSlider(1, 1, 1, 1)} className="home_imagenes_slider">
                  {
                    dataImagenesHome.map((img, i) => {
                      return (
                        <div className="iitem" key={i}>
                          <div className="home_imagenes_item">
                            <img src={img.image} alt="" />
                          </div>
                        </div>
                      )
                    })
                  }
                </Slider>
              </React.Fragment>
            </div>
            <div  className='autor_fotos'>
              <span>📸 <a href='https://www.instagram.com/danny.gamboa/' target="_blank" rel='noopener noreferrer'>@danny.gamboa</a></span>
            </div>
            
          </div>
          </div>
        )
      }


  return (

    <section className="blog_area sec_pad">
      <div className="container">
        {
          haveSlider()
        }
      </div>
    </section>
  );
}

