import React from 'react';

const RutasTuristicasHomeBanner =()=>{
    return(
        <section className="rutas_turisticas_banner_area">
            <div className="parallax-effect" style={{background: "linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url(" + (require("../../img/home/Chirripo1.jfif")) + ")  center center/cover no-repeat"}}></div>
            <div className="container">
                <div className="rutas_turisticas_banner_content">
                    {/*<div className="round wow zoomIn" data-wow-delay="0.2s"></div>
                     <h6 className="wow fadeInUp" data-wow-delay="0.6s">January 24, 2020</h6> */}
                    <h2 className="wow fadeInUp" data-wow-delay="0.8s"><span>¿Qué hacer en</span> Pérez<span>?</span></h2>
                    {/* <a className="rutas_turisticas_btn btn_hover wow fadeInLeft" data-wow-delay="0.9s" href="/#">Register Now</a>
                    <a className="rutas_turisticas_btn rutas_turisticas_btn_two btn_hover wow fadeInRight" data-wow-delay="0.9s" href="/#"><i className="arrow_triangle-right_alt2"></i>How we Work</a>*/}
                </div> 
            </div>
        </section>
    )
}

export default RutasTuristicasHomeBanner;