import React from 'react';
import Sectitle from './Title/Sectitle';
import Slider from 'react-slick';
import { settingsSlider } from '../helpers/helpers';
import { useTranslation } from 'react-i18next';

const DistritosShowCase =()=>{

    const [t, i18n] = useTranslation("global");

    return(
        <section  className="app_screenshot_area mt_75" id="showcase">
            <div  className="container custom_container p0">
                <Sectitle sClass="sec_title text-center mb_70" Title={ t('home.districts') } tClass="t_color3 mb_20" 
                TitleP={ t('home.districts_description') }/>
                <div  className="app_screen_info">
                    <Slider  className="app_screenshot_slider" {...settingsSlider(5, 3, 2, 1)}>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/sanisidro' className="image-link"><img src={require('../img/distritos/Distrito-01.png')} alt="San Isidro de El General"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/general'  className="image-link"><img src={require('../img/distritos/Distrito-04.png')} alt="El General"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/danielflores'  className="image-link"><img src={require('../img/distritos/Distrito-03.png')} alt="Daniel Flores"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/rivas'  className="image-link"><img src={require('../img/distritos/Distrito-02.png')} alt="Rivas"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/sanpedro'  className="image-link"><img src={require('../img/distritos/Distrito-08.png')} alt="San Pedro"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/platanares'  className="image-link"><img src={require('../img/distritos/Distrito-06.png')}alt="Platanares"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/pejibaye'  className="image-link"><img src={require('../img/distritos/Distrito-07.png')} alt="Pejibaye"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/cajon'  className="image-link"><img src={require('../img/distritos/Distrito-05.png')} alt="Cajon"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/baru'  className="image-link"><img src={require('../img/distritos/Distrito-11.png')} alt="Baru"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/rionuevo'  className="image-link"><img src={require('../img/distritos/Distrito-09.png')} alt="Rio Nuevo"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/paramo'  className="image-link"><img src={require('../img/distritos/Distrito-10.png')} alt="Paramo"/></a>
                            </div>
                        </div>
                        <div  className="item">
                            <div  className="screenshot_img">
                                <a href='/distritos/laamistad'  className="image-link"><img src={require('../img/distritos/Distrito-12.png')} alt="La Amistad"/></a>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}
export default DistritosShowCase;