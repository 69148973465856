import React, { useState, useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { useTranslation } from "react-i18next"
import EventosBanner from '../components/Banner/EventosBanner';
import EventosCardSection from '../components/Eventos/EventosCardSection';
import { getEventosWithAllFilters } from '../services/eventos';

const Eventos =()=> {

    const [ eventos, setEventos ] = useState([])
    const { t } = useTranslation("global");

    useEffect(()=>{
        getEventos()
        window.scrollTo(0, 0)
    
        return ()=>{
            setEventos([])
        }
    },[])
    
    const getEventos = ()=>{
        getEventosWithAllFilters('', 0, 9999999).then(
            res => {
                if(res.data.eventos){
                    setEventos(res.data.eventos)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar   cClass="custom_container p0"/>
            <EventosBanner/>
            <EventosCardSection eventos={eventos}/>
            <AtraccionesActionThree/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Eventos;