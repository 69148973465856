import React, { useState, useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import AtraccionesBanner from '../components/Banner/AtraccionesBanner';
import AtraccionesSearch from '../components/Atracciones/AtraccionesSearch';
import SeoTitle from '../components/Title/SeoTitle';
import AtraccionesSectionCardsOne from '../components/Atracciones/AtraccionesSectionCardsOne';
import AtraccionesActionOne from '../components/Atracciones/AtraccionesActionOne';
import AtraccionesSectionCardTwo from '../components/Atracciones/AtraccionesSectionCardTwo';
import AtraccionesActionTwo from '../components/Atracciones/AtraccionesActionTwo';
import AtraccionesSectionThree from '../components/Atracciones/AtraccionesSectionThree';
import AtraccionesCardSlider from '../components/Atracciones/AtraccionesCardSlider';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { getAtraccionesWithImage } from '../services/atracciones';
import { useTranslation } from "react-i18next"

const Atracciones =()=> {

    const [ atracciones, setAtracciones ] = useState([])
    const { t } = useTranslation("global");

    useEffect(()=>{
        getAtracciones()
        window.scrollTo(0, 0)
    
        return ()=>{
            setAtracciones([])
        }
    },[])
    
    const getAtracciones = ()=>{
        getAtraccionesWithImage(15).then(
            res => {
                if(res.data.atracciones){
                    setAtracciones(res.data.atracciones)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar   cClass="custom_container p0"/>
            <AtraccionesBanner/>
            <AtraccionesSearch/>
            <AtraccionesSectionCardsOne atracciones={atracciones.slice(0, 4)}/>
            <AtraccionesActionOne aClass="h_action_area" aimage="action1.png" colClass="col-lg-6" colClassTwo="col-lg-6" Atitle={ t("attractions.why_should_i_know_perez_zeledon") } Atext={ t("attractions.response_why_should_i_know_perez_zeledon") } btnText={ t("attractions.find_the_right_attraction_for_me") }/>
            <AtraccionesSectionCardTwo atracciones={atracciones.slice(4, 6)}/>
            <AtraccionesSectionThree />
            <AtraccionesActionTwo/>
            
            <section className="case_studies_area sec_pad">
                <div className="container">
                    <SeoTitle Title={ t("attractions.attractions_you_cannot_let_pass_by") } TitleP={ t("attractions.this_are_some_attractions") }/>
                    <AtraccionesCardSlider atracciones={atracciones.slice(6, 15)} />
                </div>
            </section>
            
            
            
            <AtraccionesActionThree/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Atracciones;