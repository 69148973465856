import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import FooterRutas from '../components/Footer/FooterRutas';
import { useParams } from "react-router-dom";
import { getEventosById } from '../services/eventos';
import EventoIndividualBanner from '../components/Banner/EventoIndividualBanner';
import EventoIndividualContainer from '../components/Eventos/EventoIndividualContainer';
  

const EventoIndividual = () => {
    let { id } = useParams()
    const [ evento, setEvento ] = useState()
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])


    useEffect(()=>{
        getEventosById(id).then(
            res => {
                if(res.data){
                    setEvento(res.data)
                }
            }
        )
    }, [id])
    
    const getBannerImg = () => {
        if(evento && evento.imagenes){
            if(evento.imagenes.length > 0){
                return evento.imagenes[0].url
            }else{
                return "defecto"
            }
        }else{
            return "defecto"
        }
    }

    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <EventoIndividualBanner breadcrumbClass="breadcrumb_area" imgName={getBannerImg()} Ptitle={evento?.nombre} Pdescription={evento?.descripcion} PtitleIngles={evento?.nombreIngles} PdescriptionIngles={evento?.descripcionIngles}/>
            <EventoIndividualContainer evento_={evento}/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default EventoIndividual;