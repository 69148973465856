import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../helpers/helpers';
import { getAllCategorias, getAllCount, getAtraccionesWithAllFilters } from '../../services/atracciones';
import { getHotelesWithAllFilters } from '../../services/hospedaje';
import { getOficinasWithAllFilters } from '../../services/oficinas';
import { getOperadoresWithAllFilters } from '../../services/operadoresTuristicos';
import { getRentadorasWithAllFilters } from '../../services/rentadoras';
import { getRestaurantesWithAllFilters } from '../../services/restaurantes';
import SearchPageItem from './SearchPageItem';
import { useTranslation } from "react-i18next"


const tipos = [
    {
        key: 'atracciones',
        value: 'Atracciones'
    },
    {
        key: 'hospedaje',
        value: 'Hospedaje'
    },
    {
        key: 'restaurantes',
        value: 'Restaurantes'
    },
    {
        key: 'operadores',
        value: 'Operadores Turísticos'
    },
    {
        key: 'oficinas',
        value: 'Oficinas Turísticas'
    },
    {
        key: 'rentacars',
        value: 'Rent a Cars'
    }
]


const SearchPageContainer =(props)=>{

    const { t } = useTranslation('global');
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    let { tipoP, filtroP, categoriasP, pagina, accesabilitiesInPath, othersInPath } = props
    
    const history = useHistory();

    const [ loading, setLoading ] = useState(false)

    const [ counts, setCounts ] = useState()
    const [ categorias, setCategorias ] = useState([])
    const [ categoriaBuscar, setCategoriaBuscar ] = useState("Todos")
    const [ subcategoriasBuscar, setSubcategoriasBuscar ] = useState([])
    const [ accebilitiesBuscar, setAccebilitiesBuscar ] = useState([])
    const [ othersBuscar, setOthersBuscar ] = useState([])
    const [ filtro, setFiltro ] = useState("")
    const [ sitios, setSitios ] = useState([])
    const [ cantidad, setCantidad ] = useState(0)

    useEffect(()=>{
        let f = ""
        let c = []
        let t = ""

        if( filtroP !== "Todos"){
            f = filtroP
        }

        let s = 0

        if(pagina !== 1){
            s = (pagina-1)*8
        }

        t = tipos.find(t=> t.key === tipoP).value
        
        
        if( categoriasP !== "Todos"){
            c = getCategoriasArray(categoriasP)
        }

        let o = []
        let acc = []

        if( accesabilitiesInPath !== "Todos"){
            acc = getCategoriasArray(accesabilitiesInPath)
        }
        if( othersInPath !== "Todos"){
            o = getCategoriasArray(othersInPath)
        }

        setFiltro(f)
        setCategoriaBuscar(t)
        setSubcategoriasBuscar(c)
        setAccebilitiesBuscar(acc)
        setOthersBuscar(o)

        handleFiltrar(null, t, f, c, s, acc, o)
        
    }, [tipoP, categoriasP, filtroP, pagina, accesabilitiesInPath, othersInPath])


    

    useEffect(()=>{
        getCount()
        getCategorias()
    },[])


    const getCategoriasArray = (categs) =>{
        let cs = categs.split(",")
        return cs
    }

    const getCount = ()=>{
        
        getAllCount().then(
            res => {
                if(res.data){
                    setCounts(res.data)
                }
            }
        )
    };

    const getCategorias = ()=>{
        getAllCategorias().then(
            res => {
                if(res.data){
                    setCategorias(res.data)
                }
            }
        )
    };

    const others = [
        'Declaración turística',
        'Permite mascotas',
        'Permite niños'
    ]

    const accessibilities = [
        'Discapacidad visual',
        'Discapacidad auditiva',
        'Discapacidad física',
        'Discapacidad cognitiva',
        'Discapacidad sicosocial',
    ]

    const getOthersAccesibilities = (o) => {
        switch(o){
            case 'Declaración turística': return ESPANOL === language ? o : 'Tourist declaration'
            case 'Permite mascotas': return ESPANOL === language ? o : 'Pets allowed'
            case 'Permite niños': return ESPANOL === language ? o : 'Children allowed'
        }
    }

    const getAccessibilities = (a) => {
        switch(a){
            case 'Discapacidad visual': return ESPANOL === language ? a : 'Visual disability'
            case 'Discapacidad auditiva': return ESPANOL === language ? a : 'Hearing disability'
            case 'Discapacidad física': return ESPANOL === language ? a : 'Physical disability'
            case 'Discapacidad cognitiva': return ESPANOL === language ? a : 'Cognitive disability'
            case 'Discapacidad sicosocial': return ESPANOL === language ? a : 'Psychosocial disability'
        }
    }

    const getClassCategoriaBuscar = (c) => {
        
        if(c === categoriaBuscar){
            return "categorie_active"
        }
        return ""
    }

    const getClassSubCategoriaBuscar = (c) => {
        if(subcategoriasBuscar.length > 0){
            for(let i=0; i<subcategoriasBuscar.length; i++){
                if(subcategoriasBuscar[i] === c){
                    return "subcategorie_active"
                }
            }
        }
        return ""
    }

    const getClassAccesibilityBuscar = (c) => {
        if(accebilitiesBuscar.length > 0){
            for(let i=0; i<accebilitiesBuscar.length; i++){
                if(accebilitiesBuscar[i] === c){
                    return "subcategorie_active"
                }
            }
        }
        return ""
    }

    const getClassOthersBuscar = (c) => {
        if(othersBuscar.length > 0){
            for(let i=0; i<othersBuscar.length; i++){
                if(othersBuscar[i] === c){
                    return "subcategorie_active"
                }
            }
        }
        return ""
    }

    const handleSubCategoriaBuscar = (e, c) => {
        let index = subcategoriasBuscar.indexOf(c)
        let sc = []
        let subCats = ""
        
        if(categoriasP !== "Todos"){
            subCats = categoriasP
        }

        if(index === -1){
            if(subCats!==""){
                subCats += `,${c}`
            }else{
                subCats += c
            }
            
        }else{
            sc = subcategoriasBuscar.filter(s => s !== c) || []
            
            if(sc.length === 0){
                subCats = "Todos"
            }else{
                subCats = ""

                for(let i=0; i<sc.length; i++){
                    if(i === 0){
                        subCats = sc[i]
                    }else{
                        subCats += `,${sc[i]}`
                    }
                }
            }
            
        }
        history.push(`/buscar/${tipoP}/${filtroP}/${subCats}/1/${accesabilitiesInPath}/${othersInPath}`);
    }


    const handleAccesabilityBuscar = (e, c) => {
        let index = accebilitiesBuscar.indexOf(c)
        let sc = []
        let accesabilitesPath = ""

        if(accesabilitiesInPath !== "Todos"){
            accesabilitesPath = accesabilitiesInPath
        }

        if(index === -1){
            if(accesabilitesPath!==""){
                accesabilitesPath += `,${c}`
            }else{
                accesabilitesPath += c
            }
            
        }else{
            sc = accebilitiesBuscar.filter(s => s !== c) || []
            
            if(sc.length === 0){
                accesabilitesPath = "Todos"
            }else{
                accesabilitesPath = ""

                for(let i=0; i<sc.length; i++){
                    if(i === 0){
                        accesabilitesPath = sc[i]
                    }else{
                        accesabilitesPath += `,${sc[i]}`
                    }
                }
            }
            
        }
        history.push(`/buscar/${tipoP}/${filtroP}/${categoriasP}/1/${accesabilitesPath}/${othersInPath}`);
    }

    const handleOthersBuscar = (e, c) => {
        let index = othersBuscar.indexOf(c)
        let sc = []
        let othersPath = ""

        if(othersInPath !== "Todos"){
            othersPath = othersInPath
        }

        if(index === -1){
            if(othersPath!==""){
                othersPath += `,${c}`
            }else{
                othersPath += c
            }
            
        }else{
            sc = othersBuscar.filter(s => s !== c) || []
            
            if(sc.length === 0){
                othersPath = "Todos"
            }else{
                othersPath = ""

                for(let i=0; i<sc.length; i++){
                    if(i === 0){
                        othersPath = sc[i]
                    }else{
                        othersPath += `,${sc[i]}`
                    }
                }
            }
            
        }
        history.push(`/buscar/${tipoP}/${filtroP}/${categoriasP}/1/${accesabilitiesInPath}/${othersPath}`);
    }


    const handleSubmitFiltrar = (e, f) => {
        if(f===""){
            f="Todos"
        }
        history.push(`/buscar/${tipoP}/${f}/${categoriasP}/1/${accesabilitiesInPath}/${othersInPath}`);
    }

    const handleFiltrar = (e, t, f, c, s, a, o) => {
        if(e){
            e.preventDefault()
        }

        setLoading(true)

        if(t === "Atracciones"){
            getAtraccionesWithAllFilters(f, s, 8, c, a, o).then(
                res => {
                    if(res.data){
                        setSitios(res.data.atracciones)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }else if(t === "Hospedaje"){
            getHotelesWithAllFilters(f, s, 8, c, a, o).then(
                res => {
                    if(res.data){
                        setSitios(res.data.hoteles)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }else if(t === "Restaurantes"){
            getRestaurantesWithAllFilters(f, s, 8, a, o).then(
                res => {
                    if(res.data){
                        setSitios(res.data.restaurantes)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }else if(t === "Operadores Turísticos"){
            getOperadoresWithAllFilters(f, s, 8, a, o).then(
                res => {
                    if(res.data){
                        setSitios(res.data.operadores)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }else if(t === "Oficinas Turísticas"){
            getOficinasWithAllFilters(f, s, 8, a, o).then(
                res => {
                    if(res.data){
                        setSitios(res.data.oficinas)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }else if(t === "Rent a Cars"){
            getRentadorasWithAllFilters(f, s, 8, a).then(
                res => {
                    if(res.data){
                        setSitios(res.data.rentadoras)
                        setCantidad(res.data.cantidad)
                    }
                }
            ).finally(()=>{
                setLoading(false)
            })
        }
    }

    const getNameCategorieESorEN = (categorie) => {
        let categorieAux = language === ESPANOL ? categorie?.nombre : categorie?.nombreIngles;
        if (categorieAux === ''){
            categorieAux = categorie?.nombre;
        }
        return categorieAux;
    }

    const getPagination = () => {
        let paginaActual = parseInt(pagina)
        let primeraPagina = 1
        let ultimaPagina = Math.floor((cantidad / 8 ))
        let paginaAnterior = -1
        let paginaSiguiente = -1
        if( paginaActual > (primeraPagina + 1) ){
            paginaAnterior = paginaActual - 1
        }
        if( paginaActual < (ultimaPagina - 1) ){
            paginaSiguiente = paginaActual + 1
        }

        return (
            <>
                {
                    paginaActual > 1 && <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${paginaActual-1}/${accesabilitiesInPath}/${othersInPath}`}><span className="next page-numbers" /*onClick={(e)=>{setSkip((paginaActual - 2) * 8)}} */><i className="ti-arrow-left"></i></span></a></li>

                }
                {
                    paginaActual > 1 &&
                    <>
                        <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${1}/${accesabilitiesInPath}/${othersInPath}`}><span className="page-numbers" /*onClick={(e)=>{setSkip((primeraPagina - 1) * 8)}} */>{primeraPagina}</span></a></li>
                        <li><span className="page-numbers etc" >...</span></li>
                    </>

                }
                {
                    paginaAnterior !== -1 && <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${paginaAnterior}/${accesabilitiesInPath}/${othersInPath}`}><span className="page-numbers" /*onClick={(e)=>{setSkip((paginaAnterior - 1) * 8)}}*/ >{paginaAnterior}</span></a></li>

                }
                <li><span aria-current="page" className="page-numbers current">{paginaActual}</span></li>
                {
                    paginaSiguiente !== -1 && <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${paginaSiguiente}/${accesabilitiesInPath}/${othersInPath}`}><span className="page-numbers" /*onClick={(e)=>{setSkip((paginaSiguiente - 1) * 8)}}*/ >{paginaSiguiente}</span></a></li>

                }
                {
                    paginaActual < ultimaPagina && 
                    <>
                        <li><span className="page-numbers etc" >...</span></li>
                        <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${ultimaPagina}/${accesabilitiesInPath}/${othersInPath}`}><span className="page-numbers" /*onClick={(e)=>{setSkip((ultimaPagina - 1) * 8)}}*/ >{ultimaPagina}</span></a></li>
                    </>
                }
                {
                    paginaActual < ultimaPagina && <li><a href={`/buscar/${tipoP}/${filtroP}/${categoriasP}/${paginaActual+1}/${accesabilitiesInPath}/${othersInPath}`}><span className="next page-numbers" /*onClick={(e)=>{setSkip(paginaActual * 8)}}*/ ><i className="ti-arrow-right"></i></span></a></li>
                }
            </>
            
        )
    }

    return(
        <section className="blog_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_grid_info">
                        <div className="row">
                            {
                                loading === false ?
                                    sitios.length > 0 ?
                                        sitios.map((s, i)=>{
                                            return <SearchPageItem key={i} sitio={s} tipo={tipoP} />
                                        })
                                    :
                                        <p>No se encontró ningún dato con la información proporcionada en la búsqueda. <strong>Por favor, revisa los filtros de búsqueda o intenta más tarde</strong>. Si el inconveniente persiste por favor comunícanoslo en la sección de contacto.</p>
                                :
                                    <p>Cargando...</p>
                            }
                        </div>
                        <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                            {
                                getPagination()
                            }
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <div className="blog-sidebar">
                            <div className="widget sidebar_widget widget_search">
                                <div className="widget_title">
                                    <h3 className="f_p f_size_20 t_color3">{ t('browser.keep_looking') }</h3>
                                </div>
                                <form className="search-form input-group">
                                    <input maxLength="40" onKeyDown={(e) => {if(e.key === "Enter"){handleSubmitFiltrar(e, filtro)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} type="search" value={filtro} onChange={(e)=>setFiltro(e.target.value)} className="form-control widget_input" placeholder={ t('global.search') }/>
                                    <button type="button" onClick={(e)=>handleSubmitFiltrar(e, filtro)}><i className="ti-search"></i></button>
                                </form>
                            </div>
                            <div className="widget sidebar_widget widget_categorie mt_60">
                                <div className="widget_title">
                                    <h3 className="f_p f_size_20 t_color3">{ t('browser.categories') }</h3>
                                    <div className="border_bottom"></div>
                                </div>
                                <ul className="list-unstyled">                                    
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Atracciones').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Atracciones")}`}>{ t('global.attractions') }</span><em>({counts?.atracciones[0].count})</em></a></li>
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Hospedaje').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Hospedaje")}`}>{ t('global.lodgings') }</span><em>({counts?.hoteles[0].count})</em></a></li>
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Restaurantes').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Restaurantes")}`}>{ t('global.restaurants') }</span><em>({counts?.restaurantes[0].count})</em></a></li>
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Operadores Turísticos').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Operadores Turísticos")}`}>{ t('global.tour_operators') }</span><em>({counts?.operadores[0].count})</em></a></li>
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Oficinas Turísticas').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Oficinas Turísticas")}`}>{ t('global.touristic_offices') }</span><em>({counts?.oficinas[0].count})</em></a></li>
                                    <li><a href={`/buscar/${tipos.find(t=>t.value === 'Rent a Cars').key}/Todos/Todos/1/${accesabilitiesInPath}/${othersInPath}`}><span className={`${getClassCategoriaBuscar("Rent a Cars")}`}>{ t('global.rental_cars') }</span><em>({counts?.rentacars[0].count})</em></a></li>
                                </ul>
                            </div>
                            <div style={{display:'block'}}>
                                <div className="widget sidebar_widget widget_tag_cloud mt_60">
                                    <div className="widget_title">
                                        <h3 className="f_p f_size_20 t_color3">{ t('accessibility.accessibilities') }</h3>
                                        <div className="border_bottom"></div>
                                    </div>
                                    <div className="post-tags" style={{marginBottom: '320px'}}> 
                                        {
                                            accessibilities.map((c, i)=>{
                                                return <span key={i} style={{width:'70%'}} className={`${getClassAccesibilityBuscar(c)}`} onClick={(e)=>{
                                                    handleAccesabilityBuscar(e, c)
                                                }}>{getAccessibilities(c)}</span>

                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    (categoriaBuscar !== 'Rent a Cars') &&
                                    <div className="widget sidebar_widget widget_tag_cloud mt_60">
                                        <div className="widget_title">
                                            <h3 className="f_p f_size_20 t_color3">{ t('global.others') }</h3>
                                            <div className="border_bottom"></div>
                                        </div>
                                        <div className="post-tags" style={{marginBottom: '200px'}}> 
                                            {
                                                others.map((c, i)=>{
                                                    return <span key={i} style={{width:'70%'}} className={`${getClassOthersBuscar(c)}`} onClick={(e)=>{
                                                        handleOthersBuscar(e, c)
                                                    }}>{getOthersAccesibilities(c)}</span>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                
                                {
                                    (categoriaBuscar === "Atracciones" /*|| categoriaBuscar === "Hospedaje"*/) &&
                                    <div className="widget sidebar_widget widget_tag_cloud mt_60">
                                        <div className="widget_title">
                                            <h3 className="f_p f_size_20 t_color3">{ t('browser.subcategories') }</h3>
                                            <div className="border_bottom"></div>
                                        </div>
                                        <div className="post-tags">
                                            {
                                                categorias.map((c, i)=>{
                                                    return <span key={i} className={`${getClassSubCategoriaBuscar(c.nombre)}`} onClick={(e)=>{
                                                        handleSubCategoriaBuscar(e, c.nombre)
                                                    }}>{getNameCategorieESorEN(c)}</span>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SearchPageContainer;