import React from 'react';
import Sectitle from '../Title/Sectitle';
import AtraccionesCardItemTwo from './AtraccionesCardItemTwo';
import { useTranslation } from "react-i18next"

const AtraccionesSectionCardTwo = ({ atracciones }) => {
    const { t } = useTranslation("global");

    return (
        <section className="h_blog_area sec_pad">
            <div className="container">
                <Sectitle Title={ t("attractions.two_attractions_that_you_cannot_miss") } TitleP={ t("attractions.there_are_hundreds_of_attractions") } sClass="hosting_title text-center" />
                <div className="row">
                    {
                        atracciones.map((a, i) => {
                            return (
                                <AtraccionesCardItemTwo id={a.id} img={a.imagenes[0].url} nombre={a.nombre} distrito={a.ubicacion?.distrito} comments="3 Comentarios" key={i} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default AtraccionesSectionCardTwo;