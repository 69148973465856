import React from 'react'

export const PerezZeledonBanner = ({titulo, descripcion}) => {

    const getImage = () =>{
        return require ('../../img/distritos/pz.jpg')
    }
    return(
        <section className="sitio_single_banner_area">
            <div className="parallax-effect" style={{background: "linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url("+getImage()+")  center center/cover no-repeat"}}></div>
            <div className="container">
                <div className="sitio_single_banner_content">
                    <h2 className="wow fadeInUp" data-wow-delay="0.8s"><span></span>{titulo}<span></span></h2>
                    {
                        descripcion.length > 1 && <p className="f_400 w_color f_size_16 l_height26">{descripcion}</p>
                    }
                    
                </div>
            </div>
        </section>
    )
}

export default PerezZeledonBanner;