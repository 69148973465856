import React, { useEffect, useState } from 'react';
import { getAllCategorias } from '../../services/atracciones';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../helpers/helpers';
import { useTranslation } from "react-i18next"

const AtraccionesSearch =()=>{
    
    const history = useHistory();
    const [ categorias, setCategorias ] = useState([])
    const [ filtro, setFiltro ] = useState("")
    const [ categoria, setCategoria ] = useState("")
    const { t } = useTranslation("global");
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';
 
    useEffect(()=>{
        getCategorias()
        return () => setCategorias([])
    },[])

    const getCategorias = ()=>{
        getAllCategorias().then(
            res => {
                if(res.data){
                    let t = [{nombre:'Todos', nombreIngles: 'All'}]
                    let array = t.concat(res.data)
                    setCategorias(array)
                    setCategoria(array[0].nombre)
                }
            }
        )
    };

    const handleFilter = (e) => {
        let f = "Todos"
        let c = "Todos"
        
        if(filtro.length > 1){
            f = filtro
        }
        if(categoria.length > 1){
            c = categoria
        }
        history.push(`/buscar/atracciones/${f}/${c}/1/Todos/Todos`);
    }

    const getNameCategory = (name) => {
        
        let nameAux = language === ESPANOL ? name?.nombre : name?.nombreIngles;
        if (!nameAux) {
            return name?.nombre;
        }
        return nameAux;   
    }

    return(
        <section className="atracciones-search_search_area sec_pad">
            <div className="map_bg"></div>
            <div className="container">
                <div className="atracciones-search_box_info">
                    <h3 className="wow fadeInUp" data-wow-delay="0.3s">{ t("attractions.what_are_you_looking_for") }</h3>
                    <form>
                        <div className="atracciones-search_form_inner">
                            <input type="text" className="form-control" placeholder={ t("attractions.write_here") } value={filtro} onKeyDown={(e) => {if(e.key === "Enter"){handleFilter(e)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} onChange={(e)=>setFiltro(e.target.value)} maxLength="60" />
                            <div className="atracciones-search_select">
                                <select className="form-control selectpickers" value={categoria} onChange={(e)=>setCategoria(e.target.value)}>
                                    {
                                        categorias.map((c, i)=>{
                                            return <option key={i}>{getNameCategory(c)}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <button onClick={handleFilter} className="hosting_btn">{ t("attractions.search") }</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default AtraccionesSearch;