import React, { useEffect, useState } from 'react';
import { Reveal } from 'react-reveal';
import Sectitle from '../Title/Sectitle';
import { useTranslation } from "react-i18next"
import EventosCardItemOne from '../Eventos/EventosCardItemOne';
import { getEventosWithAllFilters } from '../../services/eventos';

const EventosHome = ({  }) => {

    const [ eventos, setEventos ] = useState([])
    const { t } = useTranslation("global");
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    useEffect(()=>{
        getEventos()
        return ()=>{
            setEventos([])
        }
    },[])
    
    const getEventos = ()=>{
        getEventosWithAllFilters('', 0, 5).then(
            res => {
                if(res.data.eventos){
                    setEventos(res.data.eventos)
                }else{

                }
            }
        )
    };

    const getDate = (evento) => {
        if(evento.fechaInicio === evento.fechaFin){
            let fechaSlice = evento.fechaInicio.slice(0,10)
            let fechaSplit = fechaSlice.split('-')
            return ` ${fechaSplit[2]}/${fechaSplit[1]}/${fechaSplit[0]}` 
        }
        let fechaInicioSlice = evento.fechaInicio.slice(0,10)
        let fechaInicioSplit = fechaInicioSlice.split('-')
        let fechaFinSlice = evento.fechaFin.slice(0,10)
        let fechaFinSplit = fechaFinSlice.split('-')
        return ` ${fechaInicioSplit[2]}/${fechaInicioSplit[1]}/${fechaInicioSplit[0]} - ${fechaFinSplit[2]}/${fechaFinSplit[1]}/${fechaFinSplit[0]}` 
    }

    return (
        <section className="app_screenshot_area mt_75" style={{marginTop: '40px!important'}}>
            <div className="container">
                <Sectitle sClass="atracciones_section_cards_one_title text-center align-middle" Title={t("events.events")} TitleP={t("events.come")} />
                <div className='row justify-content-center'>
                    <div className='col text-center'>
                        <div className="event_about_item wow fadeInUp" data-wow-delay="0.2s">
                            <a href="/eventos" className="hosting_btn btn_hover text-center">{ t('events.see_all') }</a>
                        </div>
                    </div>
                </div>
                {/* <Reveal bottom cascade className="justify-context-center"> */}
                    <div className="row justify-content-center mt_30">
                        {
                            eventos.map((a, i) => {
                                return (
                                    <div className="col-lg-3 col-sm-6" key={i}>
                                        <EventosCardItemOne fecha={getDate(a)} img={a.imagenes[0].url} nombre={language === ESPANOL ? a.nombre : a.nombreIngles} distrito={a.ubicacion?.distrito} id={a.id} s={a} />
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                {/* </Reveal> */}
            </div>
        </section>
    )
}
export default EventosHome;