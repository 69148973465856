import React, { useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import PerezZeledonItems from '../components/PerezZeledon/PerezZeledonItems';
import DistritosContainer from '../components/PerezZeledon/DistritosContainer';
import PerezZeledonBanner from '../components/PerezZeledon/PerezZeledonBanner';
import Sectitle from '../components/Title/Sectitle';
import PerezZeledonInfoItem from '../components/PerezZeledon/PerezZeledonInfoItem';
import FooterRutas from '../components/Footer/FooterRutas';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { Biodiversidad } from '../components/PerezZeledon/Biodiversidad';
import { useTranslation } from 'react-i18next';

const PerezZeledon = () => {

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const { t } = useTranslation('global');

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <PerezZeledonBanner titulo="Pérez Zeledón" descripcion={ t('perez_zeledon.description') }/>
            <section className="mt_70">
                <div className="container">
                    <div className='row pz-menu'>
                        <a href="#pz_distritos" className="hosting_btn btn_hover">{ t('perez_zeledon.districts') }</a>
                        <a href="#pz_historia" className="hosting_btn btn_hover">{ t('perez_zeledon.history') }</a>
                        <a href="#pz_geologia" className="hosting_btn btn_hover">{ t('perez_zeledon.geological_features') }</a>
                        <a href="#pz_geografia" className="hosting_btn btn_hover">{ t('perez_zeledon.geographic_location') }</a>
                        <a href="#pz_florafauna" className="hosting_btn btn_hover">{ t('perez_zeledon.flora_and_fauna') }</a>
                    </div>
                </div>
            </section>
            <DistritosContainer id="pz_distritos" />
            <section className="process_area bg_color sec_pad" id='pz_historia'>
                <div className="container">
                    <Sectitle sClass="sec_title text-center" Title={ t('perez_zeledon.history') } tClass="t_color3" TitleP={ t('perez_zeledon.history_description') }/>
                    <div className="features_info">
                        <img className="dot_img" src={require ('../img/home4/divider.png')} alt=""/>
                        <PerezZeledonItems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="1.jpg" iImg="icon01.png" ftitle={ t('perez_zeledon.the_origin_of_the_canton') } descriptions={ t('perez_zeledon.origin_description') }/>
                        <PerezZeledonItems rowClass="row" aClass="pl_100" fimage="2.jpg" iImg="icon02.png" ftitle={ t('perez_zeledon.education_grows') } descriptions={ t('perez_zeledon.education_description') }/>
                        <PerezZeledonItems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="3.jpg" iImg="icon03.png" ftitle={ t('perez_zeledon.we_are_canton') } descriptions={ t('perez_zeledon.we_are_canton_description') }/>
                        <PerezZeledonItems rowClass="row" aClass="pl_100" fimage="4.jpg" iImg="icon04.png" ftitle={ t('perez_zeledon.the_first_municipality') } descriptions={ t('perez_zeledon.the_first_municipality_description') }/>
                        <PerezZeledonItems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="pz.jpg" iImg="icon05.png" ftitle={ t('perez_zeledon.the_canton_continues_to_grow') }  descriptions={ t('perez_zeledon.the_canton_continues_to_grow_description') }/>  
                        <div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div>
                    </div>
                </div>
            </section>
            <Biodiversidad creditos={ t('perez_zeledon.biodiversity_credits') }  titulo={ t('perez_zeledon.biodiversity_of_perez_zeledon') } descripcion="" />
            <PerezZeledonInfoItem id="pz_geologia" img="geologia.jpg" titulo={ t('perez_zeledon.geological_features') } descripcion={ t('perez_zeledon.geological_features_description') }  creditos={ t('perez_zeledon.geological_features_credits') }/>
            <PerezZeledonInfoItem id="pz_geografia" img="geografia.png" titulo={ t('perez_zeledon.geographic_location') }  descripcion={ t('perez_zeledon.geographic_location_description') }  creditos={ t('perez_zeledon.geographic_location_credits') }/>
            <PerezZeledonInfoItem id="pz_florafauna" img="florafauna.jpg" titulo={ t('perez_zeledon.flora_and_fauna') }  descripcion={ t('perez_zeledon.flora_and_fauna_description') }  creditos={ t('perez_zeledon.flora_and_fauna_credits') }/>
            <AtraccionesActionThree />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}

export default PerezZeledon;