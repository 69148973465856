import React from 'react';
import { getFirtsImage, getValueDistritos } from '../../helpers/helpers';

export const SearchPageItem = (props) => {
    let{ sitio, tipo }=props;
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    const getUrl = () => {
        let t=""
        if(tipo === "operadores"){
            t = "operadoresTuristicos"
        }else if(tipo === "oficinas"){
            t = "oficinasTuristicas"
        }else{
            t = tipo
        }

        return "/sitio/"+t+"/"+sitio.id
    }

    const getDescripcion = ()=>{
        if(sitio.descripcion){
            return <p>{getAtribute(getNameDescriptionESorEN(sitio))}</p>
        }
    }
    
    const getNameDescriptionESorEN = (sitio) => {
        let desAux = language === ESPANOL ? sitio?.descripcion : sitio?.descripcionIngles;
        if (desAux === ''){
            desAux = sitio?.descripcion;
        }
        return desAux;
    }

    const getAtribute = (word)=>{
        if(word.length > 85){
            return `${word.slice(0, 80)}...`;
        }else{
            return word;
        }
    };


    

    const getTelefono = () => {
        if(sitio.contactos){
            if(sitio.contactos.length > 0){
                for(let i = 0; i < sitio.contactos.length; i++ ){
                    if(sitio.contactos[i].tipo === "Teléfono"){
                        return (
                            <a className="post-info-comments" href={`tel:${sitio.contactos[i].valor}`}>
                                <i className="ti-mobile" aria-hidden="true"></i>
                                <span>{sitio.contactos[i].valor}</span>
                            </a>
                        )
                    }
                }
            }
        }
    }

    const getDistrito = () => {
        if(sitio.ubicacion){
            return <a href={`/distritos/${getValueDistritos(sitio.ubicacion.distrito)}`} className="learn_btn_two"><i className="icon_pin_alt"></i> {sitio.ubicacion.distrito}</a>
        }
    }

    return(
        <div className="col-lg-6">
            <div className="blog_list_item blog_list_item_two">
                <a href={`${getUrl()}`}><img className="img-fluid" src={getFirtsImage(sitio)} alt={sitio.nombre} /></a>
                <div className="blog_content">
                    <a href={`${getUrl()}`}>
                        <h5 className="blog_title">{sitio.nombre}</h5>
                    </a>
                    {
                        getDescripcion()
                    }
                    <div className="post-info-bottom">
                        {
                            getDistrito()
                        }
                        {
                            getTelefono()
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default SearchPageItem;