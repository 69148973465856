import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import OficinasBanner from '../components/Banner/OficinasBanner';
import OficinasSectionOne from '../components/OficinasTuristicas/OficinasSectionOne';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { getAllOficinas } from '../services/oficinas';

const Oficinas = () => {

    const [ oficinas, setOficinas ] = useState([])

    useEffect(()=>{
        getOficinas()
        window.scrollTo(0, 0)
    
        return ()=>{
            setOficinas([])
        }
    },[])

    const getOficinas = ()=>{
        getAllOficinas().then(
            res => {
                if(res.data){
                    setOficinas(res.data)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar  cClass="custom_container p0"/>
            <OficinasBanner />
            <OficinasSectionOne oficinas={oficinas} />
            <AtraccionesActionThree />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Oficinas;