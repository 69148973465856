import axios from 'axios';
import { API_URL } from '../config/apiConnection';

export const getOperadoresWithImage = async(take) => {
    const response = await axios.get(`${API_URL}/operadoresTuristicos/haveimages`, {
        params:{
            take: take,
        }
    });
    return response;
}

export const getOperadoresById = async(id) => {
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${API_URL}/operadoresTuristicos/id/?id=${id}`, { headers });
    return response;
}

export const getOperadoresWithUbicacion = async(nombre, skip, take, provincia, canton, distrito) => {
    const response = await axios.get(`${API_URL}/operadoresTuristicos/find/ubicacion`, {
        params:{
            nombre: nombre,
            skip: skip,
            take: take,
            provincia: provincia,
            canton: canton,
            distrito: distrito
        }
    });
    return response;
}

export const getOperadoresWithSameCategory = async(nombres, skip, take, categorias) => {
    const response = await axios.get(`${API_URL}/operadoresTuristicos/find/same/category`, {
        params:{
            nombres: nombres,
            skip: skip,
            take: take,
            categorias: categorias,
        }
    });
    return response;
}

export const getOperadoresWithAllFilters = async(filtro, skip, take, accesabilidades, otros) => {
    const response = await axios.get(`${API_URL}/operadoresTuristicos/find/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
            accesabilidades: accesabilidades,
            otros: otros
        }
    });
    return response;
}