import React, {Component} from 'react';
import Slider from 'react-slick/';
import { getValueDistritos, settingsSlider } from '../../helpers/helpers';

class AtraccionesCardSlider extends Component {
    render() {
        let {atracciones} = this.props

        
        return (
            <React.Fragment>
                <Slider {...settingsSlider(3, 3, 2, 1)} className="case_studies_slider">
                    {
                        atracciones.map((a, i)=>{
                            return (
                                <div className="iitem" key={i}>
                                    <div className="studies_item">
                                        <img src={a.imagenes[0].url} alt=""/>
                                        <div className="text">
                                            <a href={`/sitio/atracciones/${a.id}`}>
                                                <h4>{a.nombre}</h4>
                                            </a>
                                            <p><a href={`/distritos/${getValueDistritos(a?.ubicacion?.distrito)}`}>{a.ubicacion?.distrito}</a></p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </React.Fragment>
        )
    }
}

export default AtraccionesCardSlider;