import axios from 'axios';
import { API_URL } from '../config/apiConnection';

export const getEventosWithAllFilters = async(filtro, skip, take) => {
    const response = await axios.get(`${API_URL}/eventos/find/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
            categorias: [],
            accesabilidades: [],
            otros: []
        }
    });
    return response;
}

export const getEventosById = async(id) => {
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${API_URL}/eventos/id/?id=${id}`, { headers });
    return response;
}