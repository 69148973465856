import React, { useState, useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import RestaurantesBanner from '../components/Banner/RestaurantesBanner';
import RestaurantesSectionOne from '../components/Restaurantes/RestaurantesSectionOne';
import RestaurantesSearch from '../components/Restaurantes/RestaurantesSearch';
import RestaurantesSectionTwo from '../components/Restaurantes/RestaurantesSectionTwo';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import { getRestaurantesWithImage } from '../services/restaurantes';

const Restaurantes = () => {

    const [ restaurantes, setRestaurantes ] = useState([])

    useEffect(()=>{
        getRestaurantes()
        window.scrollTo(0, 0)
    
        return ()=>{
            setRestaurantes([])
        }
    },[])

    const getRestaurantes = ()=>{
        getRestaurantesWithImage(12).then(
            res => {
                if(res.data.restaurantes){
                    setRestaurantes(res.data.restaurantes)
                }else{

                }
            }
        )
    };

    return(
        <div className="body_wrapper">
            <CustomNavbar  cClass="custom_container p0"/>
            <RestaurantesBanner />
            <RestaurantesSectionOne restaurantes={restaurantes.slice(0, 3)}/>
            <RestaurantesSearch />
            <RestaurantesSectionTwo restaurantes={restaurantes.slice(3, 12)} FooterData={FooterData}/>
            <AtraccionesActionThree />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Restaurantes;