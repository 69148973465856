import React from "react";
import { useTranslation } from "react-i18next";

const AtraccionesSectionThree = () => {
  const { t } = useTranslation("global");

  return (
    <section className="chat_core_features_area" style={{paddingBottom: "120px"}}>
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            { t("attractions.categories_with_unforgettable_attractions") }
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <div className="round_circle"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/atracciones/cataratas.png")} alt="" />
              </div>
              <a href="/buscar/atracciones/Todos/Cataratas/1/Todos/Todos">
                <h4>{ t("attractions.waterfalls") }</h4>
              </a>
              <p>
              { t("attractions.waterfalls_description") }
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                <div className="round_circle two"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/atracciones/agroturismo.png')} alt="" />
              </div>
              <a href="/buscar/atracciones/Todos/Agroturismo/1/Todos/Todos">
                <h4>{ t("attractions.agrotourism") }</h4>
              </a>
              <p>
              { t("attractions.agrotourism_description") }
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                <div className="round_circle three"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/atracciones/senderismo.png')} alt="" />
              </div>
              <a href="/buscar/atracciones/Todos/Senderismo/1/Todos/Todos">
                <h4>{ t("attractions.trekking") }</h4>
              </a>
              <p>
              { t("attractions.trekking_description") }
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AtraccionesSectionThree;
