import axios from 'axios';
import { API_URL } from '../config/apiConnection';

export const getAllOficinas = async() => {
    const response = await axios.get(`${API_URL}/oficinasTuristicas/`)
    return response;
}

export const getOficinasById = async(id) => {
    const headers = { 
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${API_URL}/oficinasTuristicas/id/?id=${id}`, { headers });
    return response;
}

export const getOficinasDifferent = async(nombre, skip, take) => {
    const response = await axios.get(`${API_URL}/oficinasTuristicas/different`, {
        params:{
            nombre: nombre,
            skip: skip,
            take: take
        }
    });
    return response;
}

export const getOficinasWithAllFilters = async(filtro, skip, take, accesabilidades, otros) => {
    const response = await axios.get(`${API_URL}/oficinasTuristicas/find/all/filters`, {
        params:{
            filtro: filtro,
            skip: skip,
            take: take,
            accesabilidades: accesabilidades,
            otros: otros
        }
    });
    return response;
}