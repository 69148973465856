import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const EventoIndividualSideBar = ({atracciones_, restaurantes_, hoteles_, oficinas_, operadores_, rentadoras_}) => {

    const [ atracciones, setAtracciones ] = useState([])
    const [ restaurantes, setRestaurantes ] = useState([])
    const [ hoteles, setHoteles ] = useState([])
    const [ oficinas, setOficinas ] = useState([])
    const [ operadores, setOperadores ] = useState([])
    const [ rentadoras, setRentadoras ] = useState([])
    const history = useHistory();

    useEffect(()=>{
        setAtracciones(atracciones_)
        setRestaurantes(restaurantes_)
        setHoteles(hoteles_)
        setOficinas(oficinas_)
        setOperadores(operadores_)
        setRentadoras(rentadoras_)
    }, [atracciones_, restaurantes_, hoteles_, oficinas_, operadores_, rentadoras_])

    const { t } = useTranslation('global')
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    const handleBuscar = (e, tipo, id) => {
        history.push(`/sitio/${tipo}/${id}`);
    }

    return (
        <div className="blog-sidebar mt_30">
            <div className="widget sidebar_widget widget_categorie">
                <div className="widget_title">
                    <h3 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'PARTICIPAN' : 'PARTICIPATE' }</h3>
                </div>
                {
                    atracciones?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Atracciones' : 'Attractions' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                atracciones?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'atracciones', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
                {
                    restaurantes?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Restaurantes' : 'Restaurants' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                restaurantes?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'restaurantes', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
                {
                    hoteles?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Hoteles' : 'Lodgings' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                hoteles?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'hospedaje', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
                {
                    oficinas?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Oficinas turísticas' : 'Touristic offices' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                oficinas?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'oficinasTuristicas', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
                {
                    operadores?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Operadores turísticos' : 'Tour operators' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                operadores?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'operadoresTuristicos', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
                {
                    rentadoras?.length > 0 && 
                    <>
                        <div >
                            <h4 className="f_p f_size_20 t_color3">{ language === ESPANOL ? 'Rentadoras de vehículos' : 'Rental cars' }</h4>
                        </div>
                        <ul className="list-unstyled">
                            {
                                rentadoras?.map(a=> (
                                    <li key={a.id}><span onClick={(e)=>handleBuscar(e, 'rentacars', a.id)}>{ a.nombre }</span></li>
                                ))
                            }
                        </ul>
                        <br/>
                    </>
                }
            </div>
        </div>
    )
}

export default EventoIndividualSideBar;