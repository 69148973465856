import React, { useState } from 'react'
import { sendMessage } from '../services/contactenos'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import { validateText } from '../helpers/helpers';
import { useTranslation } from 'react-i18next';

export const Contactos = () => {

    const history = useHistory();
    const { t } = useTranslation('global');

    const [ motivoMensaje, setMotivoMensaje ] = useState("0")
    const [ asunto, setAsunto ] = useState("")
    const [ disabledAsunto, setDisabledAsunto ] = useState(true)
    const [ nombre, setNombre ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ mensaje, setMensaje ] = useState("")
    
    const handleMotivoMensaje = (e)=>{
        let v = e.target.value
        setMotivoMensaje(v)
        if(v !== "3"){
            setDisabledAsunto(true)
            if( v === "1"){
                setAsunto("Quiero que mi emprendimiento aparezca en la página")
            }else if(v === "2"){
                setAsunto("Soy dueño de un emprendimiento y tengo una duda")
            }
        }else{
            setDisabledAsunto(false)
            setAsunto("")
        }
    }

    const validarCampos = ()=>{
        if(nombre && nombre !== "" && email && email !== "" && asunto && asunto !== "" && mensaje && mensaje !== ""){
            return true;
        }else{
            return false;
        }
    }

    const enviarMensaje = (e)=>{
        e.preventDefault();
        if(validarCampos()){            
            sendMessage(nombre, email, asunto, mensaje).then(res=>{                
                if(res.status === 200 && res.data.id > 0 ){
                    
                    Swal.fire({
                        icon: 'success',
                        title: t('contact.very_good'),
                        text: t('contact.very_good_message'),
                        showConfirmButton: true,
                    }).then(()=>{
                        history.push(`/contacto`);
                    });
                    
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: `Opps!`,
                        text: t('contact.bad_message'),
                        showConfirmButton: false,
                        timer: 2500
                    })
                }

            }).catch(e=>{
                Swal.fire({
                    icon: 'error',
                    title: `Opps!`,
                    text: t('contact.bad_message'),
                    showConfirmButton: false,
                    timer: 2500
                })
            }).finally(f=>{
                setNombre("");
                setAsunto("");
                setEmail("");
                setMensaje("");
                setMotivoMensaje("0")
            })
        }
    }

    return(
        <section className="contact_info_area sec_pad bg_color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="contact_info_item">
                            <h6 className="f_p f_size_20 t_color3 f_500 mb_20">+ Info</h6>
                            <p className="f_400 f_size_15"><span className="f_400 t_color3">{ t('contact.phone') }:</span> <a href="tel:50688388535" target="_blank"  rel='noopener noreferrer' >(+506) 88388535</a></p>
                            <p className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span> <a href="mailto:erick.madrigal.villanueva@una.ac.cr" target="_blank"  rel='noopener noreferrer' >erick.madrigal.villanueva@una.ac.cr</a></p>
                            <p className="f_400 f_size_15"><span className="f_400 t_color3">Facebook:</span> <a href="https://www.facebook.com/rutasturisticaspz" target="_blank"  rel='noopener noreferrer' >rutasturisticaspz</a></p>
                        </div>
                    </div>
                    <div className="contact_form col-lg-9">
                        <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{ t('contact.leave_us_a_message') }</h2>
                        <form className="contact_form_box" id="contactForm">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group text_box">
                                        <input maxLength="60" onKeyDown={(e) => {if(e.key === "Enter"){return null}else{validateText(e, /[^a-zA-Z' ]/g)}}} type="text" id="nombre" name="nombre" placeholder={ t('contact.your_name') } value={nombre} onChange={(e)=>{setNombre(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group text_box">
                                        <input maxLength="60" type="text" name="email" id="email" placeholder={ t('contact.your_email') } value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="form-group text_box">
                                        <select className="form-control selectpickers" value={motivoMensaje} onChange={handleMotivoMensaje}>
                                            <option hidden selected value={0}>{ t('contact.reason_for_the_message') }</option>
                                            <option value={1}>{ t('contact.reason_one') }</option>
                                            <option value={2}>{ t('contact.reason_two') }</option>
                                            <option value={3}>{ t('contact.reason_three') }</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    motivoMensaje === "3" &&
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input maxLength="100" onKeyDown={(e) => {if(e.key === "Enter"){return null}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} type="text" id="asunto" name="asunto" placeholder="Asunto" value={asunto} onChange={(e)=>{setAsunto(e.target.value)}} disabled = {disabledAsunto} />
                                        </div>
                                    </div>
                                }
                                {
                                    motivoMensaje !== "0" &&
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea maxLength="250" onKeyDown={(e) => {if(e.key === "Enter"){return null}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} value={mensaje} onChange={(e)=>{setMensaje(e.target.value)}} name="mensaje" id="mensaje" cols="30" rows="10" placeholder={ t('contact.write_the_message_here') }></textarea>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                            <button className="btn_three" onClick={enviarMensaje}>{ t('contact.send_message') }</button>
                        </form>
                        <div id="success">Your message succesfully sent!</div>
                        <div id="error">Opps! There is something wrong. Please try again</div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}



export default Contactos;