import React, { useState } from 'react';
import SeoTitle from '../Title/SeoTitle';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

export const HospedajeSearch = () => {

    const { t } = useTranslation('global');
    const [ filtro, setFiltro ] = useState("")
    const history = useHistory();
    
    const handleSearch = (e) => {
        let f = "Todos"
        
        if(filtro.length > 1){
            f = filtro
        }
        history.push(`/buscar/hospedaje/${f}/Todos/1/Todos/Todos`);
    }

    return(
        <React.Fragment>
            <section className="hospedaje_search_area">
                <div className="overlay_img"></div>
                <div className="container">
                    <SeoTitle Title={ t('lodging.search_for_the_ideal_accommodation_for_you') }/>
                    <form className="row hospedaje_search_form">
                        <div className="input-group col-lg-9 col-md-8 col-sm-6">
                            <input type="text" value={filtro} onKeyDown={(e) => {if(e.key === "Enter"){handleSearch(e)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} onChange={(e)=>setFiltro(e.target.value)} name="website" id="website" placeholder={ t('lodging.lodges_name') } className="form-control" maxLength="60"/>
                        </div>
                        <div className="input-group col-lg-3 col-md-4 col-sm-6">
                            <input type="button" onClick={handleSearch} value={ t('lodging.search') } className="check-btn"/>
                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HospedajeSearch;