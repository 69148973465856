import React from 'react'
import Reveal from 'react-reveal/Reveal';
import { useTranslation } from 'react-i18next';

export const MapaItem = ({titulo, img, url}) => {

    const { t } = useTranslation('global');

    return (
    <>
        <div className="col-lg-4 mt_75">
            <Reveal effect="fadeInLeft">
                    <div className="agency_featured_img text-center">
                        <img src={require ("../../img/mapas/"+img)} alt=""/>
                    </div>
            </Reveal>
        </div>
        <div className="col-lg-2">
            <div className={`agency_featured_content wow fadeInLeft`} data-wow-delay="0.6s">
                <Reveal effect="fadeInRight">
                    <h3>{titulo}</h3>
                    <a href={url} target="_blank"  rel='noopener noreferrer' className="mapa_item_btn">{ t('maps.see_map') }<i className="arrow_right"></i></a>
                </Reveal>
            </div>
        </div>
    </>
  )
}
