import React, {Component} from 'react';
import { getHrefCall } from '../../helpers/helpers';

class EventosCardItemOne extends Component {
    render(){

        const getContactoIcon = (tipoContacto) => {
            if(tipoContacto === "Página web"){
                return "ti-world"
            }else if(tipoContacto === "Email"){
                return "ti-email"
            }else if(tipoContacto === "Teléfono"){
                return "ti-mobile"
            }else if(tipoContacto === "Facebook"){
                return "ti-facebook"
            }else if(tipoContacto === "Instagram"){
                return "ti-instagram"
            }
        }
    
        const getContactoHref = (contacto) =>{
            if(contacto.tipo === "Facebook" || contacto.tipo === "Instagram" || contacto.tipo === "Página web"){
                return contacto.valor
            }else{
                if(contacto.tipo === "Email"){
                    return "mailto:"+contacto.valor
                }else if(contacto.tipo === "Teléfono"){
                    return getHrefCall(contacto.valor)
                }
            }
        }
    
        const haveContactos = (sitio) => {
            if(sitio?.contactos){
                if(sitio.contactos.length > 0){
                    return (
                        <ul className="list-unstyled">
                            {
                                sitio.contactos.map((c, i)=>{
                                    return(
                                        <li key={i}><a href={getContactoHref(c)}><i className={getContactoIcon(c.tipo)}></i></a></li>
                                    )
                                })
                            }
                        </ul>
                    )
                }
            }
        }

        

        var {img, nombre, distrito, id, s, fecha}= this.props;

        return(
            <div className="ex_team_item">
                <img src={img} alt="team"/>
                <div className="team_content">
                    <a href={`/evento/${id}`}>
                        <h3 className="f_p f_size_16 f_600 t_color3">{nombre}</h3>
                    </a>
                    <h5>{fecha}</h5>
                </div>
                <div className="hover_content">
                    <div className="n_hover_content">
                        {
                            haveContactos(s)
                        }
                        <div className="br"></div>
                        <a href={`/evento/${id}`}>
                            <h3 className="f_p f_size_16 f_600 w_color">{nombre}</h3>
                        </a>
                        <h5>{distrito}</h5>
                        <h5>{fecha}</h5>
                    </div>
                </div>
            </div>
        )
    }
}
export default EventosCardItemOne;