import React from 'react';
import { useTranslation } from "react-i18next"

const AtraccionesActionThree =()=> {

    const [t, i18n] = useTranslation("global");

    return(
        <section className="atracciones_action_three_area">
            <div className="container">
                <div className="erp_action_content text-center">
                    <img src={require("../../img/atracciones/feliz.png")} alt=""/>
                    <h3>{ t('home.give_to_yourself_the_opportunity') }</h3>
                    <p><span>{t('home.come_to_perez')}</span></p>
                </div>
            </div>
        </section>
    )
}
export default AtraccionesActionThree;