import React from 'react'
import Fade from 'react-reveal/Fade';

export const PerezZeledonInfoItem = (props) => {

    let { id, img, titulo, descripcion, creditos } = props

    const getCreditos = () => {
        if(creditos){
            if(creditos.length > 0){
                return <p className='creditos'>{creditos}</p>
            }
        }
    }
    return (
        <section className="seo_features_one sec_pad" id={id}>
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="seo_features_img">
                            <img src={require(`../../img/distritos/${img}`)} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <Fade bottom cascade>
                            <div className="seo_features_content">
                                <h2>{titulo}</h2>
                                <p className='text-justify'>{descripcion}</p>
                                {
                                    getCreditos()
                                }
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PerezZeledonInfoItem;