import React from 'react';
import Sectitle from '../Title/Sectitle';
import { useTranslation } from "react-i18next"
import AtraccionesCardItemTwo from '../Atracciones/AtraccionesCardItemTwo';
import EventosCardItem from './EventosCardItem';

const EventosCardSection = ({ eventos }) => {
    const { t } = useTranslation("global");

    return (
        <section className="h_blog_area sec_pad">
            <div className="container">
                <Sectitle Title={ t("events.section_card_title") } TitleP="" sClass="hosting_title text-center" />
                <div className="row">
                    {
                        eventos.map((a, i) => {
                            return (
                                <EventosCardItem id={a.id} img={a.imagenes && a.imagenes.length > 0 ? a.imagenes[0].url : require ('../../img/sinimagen.png')  } evento={a} distrito={a.ubicacion?.distrito} comments="3 Comentarios" key={i} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default EventosCardSection;