import React, { useEffect, useState } from 'react'
import { getFirtsImage, getTelefono } from '../../helpers/helpers'
import { getAtraccionesWithUbicacion } from '../../services/atracciones'
import { getHotelesWithUbicacion } from '../../services/hospedaje'
import { getOperadoresWithUbicacion } from '../../services/operadoresTuristicos'
import { getRestaurantesWithUbicacion } from '../../services/restaurantes'
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';

export const DistritoContainer = ({distrito}) => {

    const [ atracciones, setAtracciones ] = useState([])
    const [ restaurantes, setRestaurantes ] = useState([])
    const [ hoteles, setHoteles ] = useState([])
    const [ operadores, setOperadores ] = useState([])
    const { t } = useTranslation("global");
    const language = localStorage.getItem('language')

    useEffect(()=>{

        getAtraccionesWithUbicacion("", 0, 3, "San José", "Pérez Zeledón", distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).then(
            res => {
                if(res.data.atracciones){
                    setAtracciones(res.data.atracciones)
                }
            }
        )
        getRestaurantesWithUbicacion("", 0, 3, "San José", "Pérez Zeledón", distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).then(
            res => {
                if(res.data.restaurantes){
                    setRestaurantes(res.data.restaurantes)
                }
            }
        )
        getHotelesWithUbicacion("", 0, 3, "San José", "Pérez Zeledón", distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).then(
            res => {
                if(res.data.hoteles){
                    setHoteles(res.data.hoteles)
                }
            }
        )
        getOperadoresWithUbicacion("", 0, 3, "San José", "Pérez Zeledón", distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).then(
            res => {
                if(res.data.operadores){
                    setOperadores(res.data.operadores)
                }
            }
        )

        return ()=>{
            setAtracciones([])
            setOperadores([])
            setRestaurantes([])
            setHoteles([])
        }
    }, [distrito])


    const getEscudo = () => {
        if(distrito){
            if(distrito?.id){
                return <img src={require(`../../img/distritos/escudos/${distrito?.id}.jpeg`)} alt="escudo" />
            }
        }
    }


    const getPoblacion = () => {
        if(distrito?.poblacion){
            if(distrito.poblacion !== -1){
                return (
                    <div className="info_item">
                        <h6>{ t('districts.population') }</h6>
                        <p>{distrito.poblacion} { t('districts.inhabitants') }</p>
                    </div>
                )
            }
        }
    }

    const getTerritorio = () => {
        if(distrito?.territorio){
            if(distrito.territorio !== -1){
                return (
                    <div className="info_item">
                        <h6>{ t('districts.territory') }</h6>
                        <p>{distrito.territorio} km<sup>2</sup></p>
                    </div>
                )
            }
        }
    }

    const getDescripcion = () => {
        if(distrito?.descripcionES && distrito.descripcionES.length > 0){

            const descripcion = language === 'es' ? distrito?.descripcionES : distrito?.descripcionEN;
            return (
                <div className="col-lg-7">
                    <div className="details_content">
                        <div className="sec_title">
                            <p className='subtitle'>{ t('districts.historical_review') }</p>
                            <p className="f_400 f_size_15 text-justify">{descripcion}</p>                                
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="col-lg-7">
                {
                    getLugaresCercanos("")
                }
            </div>
        )
    }

    const getUrlVideo = () => {

        if(distrito){
            if(distrito?.id){
                return distrito.urlVideo;
            }
        }
    }

    const getLugaresCercanos = (classname) => {
        return (
            <>
            {
                atracciones.length > 0 &&
                <>
                    <div className="blog_post mt_70">
                        <div className="widget_title">
                            <h3 className="f_p f_size_20 t_color3">{ t('districts.attractions_in_the_district') }</h3>
                            <div className="border_bottom"></div>
                        </div>
                        <div className="row">
                            {
                                atracciones.map((lugar, i)=>{
                                    return (
                                        <div className="col-lg-4 col-sm-6" key={i}>
                                            <div className="blog_post_item">
                                                <div className={`blog_img ${classname}`}>
                                                    <img src={getFirtsImage(lugar)} alt=""/>
                                                </div>
                                                <div className="blog_content">
                                                    <div className="entry_post_info">
                                                        <a href="/buscar/atracciones/Todos/Todos/1/Todos/Todos">{ t('global.attractions') }</a>
                                                    </div>
                                                    <a href={`/sitio/atracciones/${lugar.id}`}>
                                                        <h5 className="f_p f_size_16 f_500 t_color">{lugar.nombre}</h5>
                                                    </a>
                                                    <p className="f_400 mb-0">{getTelefono(lugar.contactos)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center mt_40 mb_40">
                            <a href={`/buscar/atracciones/${distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/Todos/1/Todos/Todos`} className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </>
            }
            {
                restaurantes.length > 0 &&
                <>
                    <div className="blog_post">
                        <div className="widget_title">
                            <h3 className="f_p f_size_20 t_color3">{ t('districts.restaurants_in_the_district') }</h3>
                            <div className="border_bottom"></div>
                        </div>
                        <div className="row">
                            {
                                restaurantes.map((lugar, i)=>{
                                    return (
                                        <div className="col-lg-4 col-sm-6" key={i}>
                                            <div className="blog_post_item">
                                                <div className={`blog_img ${classname}`}>
                                                    <img src={getFirtsImage(lugar)} alt=""/>
                                                </div>
                                                <div className="blog_content">
                                                    <div className="entry_post_info">
                                                        <a href="/buscar/restaurantes/Todos/Todos/1/Todos/Todos">{ t('global.restaurants') }</a>
                                                    </div>
                                                    <a href={`/sitio/restaurantes/${lugar.id}`}>
                                                        <h5 className="f_p f_size_16 f_500 t_color">{lugar.nombre}</h5>
                                                    </a>
                                                    <p className="f_400 mb-0">{getTelefono(lugar.contactos)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center mt_40 mb_40">
                            <a href={`/buscar/restaurantes/${distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/Todos/1/Todos/Todos`} className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </>
            }
            {
                hoteles.length > 0 &&
                <>
                    <div className="blog_post">
                        <div className="widget_title">
                            <h3 className="f_p f_size_20 t_color3">{ t('districts.lodging_in_the_district') }</h3>
                            <div className="border_bottom"></div>
                        </div>
                        <div className="row">
                            {
                                hoteles.map((lugar, i)=>{
                                    return (
                                        <div className="col-lg-4 col-sm-6" key={i}>
                                            <div className="blog_post_item">
                                                <div className={`blog_img ${classname}`}>
                                                    <img src={getFirtsImage(lugar)} alt=""/>
                                                </div>
                                                <div className="blog_content">
                                                    <div className="entry_post_info">
                                                        <a href="/buscar/hospedaje/Todos/Todos/1/Todos/Todos">{ t('global.lodgings') }</a>
                                                    </div>
                                                    <a href={`/sitio/hospedaje/${lugar.id}`}>
                                                        <h5 className="f_p f_size_16 f_500 t_color">{lugar.nombre}</h5>
                                                    </a>
                                                    <p className="f_400 mb-0">{getTelefono(lugar.contactos)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center mt_40 mb_40">
                            <a href={`/buscar/hospedaje/${distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/Todos/1/Todos/Todos`} className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </>
            }
            {
                operadores.length > 0 &&
                <>
                    <div className="blog_post">
                        <div className="widget_title">
                            <h3 className="f_p f_size_20 t_color3">{ t('districts.touristic_operators_in_the_district') }</h3>
                            <div className="border_bottom"></div>
                        </div>
                        <div className="row">
                            {
                                operadores.map((lugar, i)=>{
                                    return (
                                        <div className="col-lg-4 col-sm-6" key={i}>
                                            <div className="blog_post_item">
                                                <div className={`blog_img ${classname}`}>
                                                    <img src={getFirtsImage(lugar)} alt=""/>
                                                </div>
                                                <div className="blog_content">
                                                    <div className="entry_post_info">
                                                        <a href="/buscar/operadores/Todos/Todos/1/Todos/Todos">{ t('global.tour_operators') }</a>
                                                    </div>
                                                    <a href={`/sitio/operadoresTuristicos/${lugar.id}`}>
                                                        <h5 className="f_p f_size_16 f_500 t_color">{lugar.nombre}</h5>
                                                    </a>
                                                    <p className="f_400 mb-0">{getTelefono(lugar.contactos)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center mt_40 mb_40">
                            <a href={`/buscar/operadores/${distrito?.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/Todos/1/Todos/Todos`} className="restaurante_btn restaurante_btn_one btn_hover">{ t('global.see_more') }</a>
                        </div>
                    </div>
                </>
            }
            </>
        )
    }

    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
               
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                {/* <i className="ti-receipt"></i> */}
                                {
                                    getEscudo()
                                }
                                <h6 className="f_p f_600 f_size_18 t_color3">{distrito?.nombre}</h6>
                            </div>
                            <div className="info_item">
                                <h6>{ t('districts.zip_code') }</h6>
                                <p>{distrito?.codigoPostal}</p>
                            </div>
                            {
                                getPoblacion()
                            }
                            {
                                getTerritorio()
                            }
                            
                        </div>
                    </div>
                    {
                        getDescripcion()
                    }
                </div>
                <div className="row">
                        <div className="col-lg-12 mt_70 mb_70">
                            <ReactPlayer  
                                width="100%"
                                url={getUrlVideo()}
                                controls
                                volume={0.5}>
                            </ReactPlayer>
                        </div>
                    </div>
                {
                    distrito?.descripcionES?.length > 0 &&
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                getLugaresCercanos("distrito")
                            }
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

export default DistritoContainer;
