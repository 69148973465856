import React from 'react';
import Sectitle from '../Title/Sectitle';
import { DistritoItem } from './DistritoItem';
import { useTranslation } from 'react-i18next';

const DistritosContainer =({id})=>{

    const { t } = useTranslation('global');

    return(
        <section className="experts_team_area sec_pad" id={id}>
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title={ t('perez_zeledon.distritos') } tClass="t_color3" TitleP={ t('perez_zeledon.districts_description') }/>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-01.png" nombre="San Isidro" href="sanisidro" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-04.png" nombre="General" href="general" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-03.png" nombre="Daniel Flores" href="danielflores" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-02.png" nombre="Rivas" href="rivas" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-08.png" nombre="San Pedro" href="sanpedro" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-06.png" nombre="Platanares" href="platanares" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-07.png" nombre="Pejibaye" href="pejibaye" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-05.png" nombre="Cajón" href="cajon" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-11.png" nombre="Barú" href="baru" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-09.png" nombre="Río Nuevo" href="rionuevo" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-10.png" nombre="Páramo" href="paramo" />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <DistritoItem distritoImage="Distrito-12.png" nombre="La Amistad" href="laamistad" />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default DistritosContainer;