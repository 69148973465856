import React, { useEffect } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import FooterRutas from '../components/Footer/FooterRutas';
import SitioIndividualBanner from '../components/Banner/SitioIndividualBanner';
import Mapas from '../components/Mapas/Mapas';
import { useTranslation } from 'react-i18next';

const Mapa = () => {

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    const { t } = useTranslation('global');

    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <SitioIndividualBanner Ptitle={ t('maps.maps_title') } Pdescription={ t('maps.maps_description') } imgName="defecto" />
            <Mapas />
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default Mapa;