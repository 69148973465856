import React, { useEffect, useState } from 'react';
import AtraccionesActionThree from '../components/Atracciones/AtraccionesActionThree';
import CustomNavbar from '../components/CustomNavbar';
import FooterRutas from '../components/Footer/FooterRutas';
import FooterData from '../components/Footer/FooterData';
import OperadoresTuristicosBanner from '../components/Banner/OperadoresTuristicosBanner';
import OperadoresTuristicosSectionOne from '../components/OperadoresTuristicos/OperadoresTuristicosSectionOne';
import OperadoresTuristicosSearch from '../components/OperadoresTuristicos/OperadoresTuristicosSearch';
import OperadoresTuristicosSectionTwo from '../components/OperadoresTuristicos/OperadoresTuristicosSectionTwo';
import { getOperadoresWithImage } from '../services/operadoresTuristicos';

const OperadoresTuristicos = () => {

    const [ operadores, setOperadores ] = useState([])

    useEffect(()=>{
        getOperadores()
        window.scrollTo(0, 0)
    
        return ()=>{
            setOperadores([])
        }
    },[])

    const getOperadores = ()=>{
        getOperadoresWithImage(12).then(
            res => {
                if(res.data.operadores){
                    setOperadores(res.data.operadores)
                }else{

                }
            }
        )
    };


    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0"/>
            <OperadoresTuristicosBanner />
            <OperadoresTuristicosSectionOne operadores={operadores.slice(0, 3)} />
            <OperadoresTuristicosSearch />
            <OperadoresTuristicosSectionTwo operadores={operadores.slice(3, 12)} FooterData={FooterData}/>
            <AtraccionesActionThree/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default OperadoresTuristicos;