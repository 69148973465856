export const dataImagenesHome = [
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FNauyacas.jpg?alt=media&token=f817cbd4-a753-47ed-b2eb-bf48ca22a3cf",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FNauyacas2.jpg?alt=media&token=2a703dd0-f40c-47ff-b6f6-2736d2eae3c9",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FNauyacas3.jpg?alt=media&token=e8ac4f45-fd02-4d58-9e3a-5bc29dcc674c",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FSanGabriel0.jpg?alt=media&token=bef973b4-5932-49bb-a30e-a8cb917a2b7f",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FSanGabriel1.jpg?alt=media&token=1c8fe48f-e44a-49a0-8dbf-302131e2bd57",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2Fecochontales0.jpg?alt=media&token=65189575-765f-4be1-814a-8a39e6e3e110",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2Fecochontales1.jpg?alt=media&token=63966cf2-39f0-401b-a91f-5b31b6d82093",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FNamu0.jpg?alt=media&token=02b818da-c9f0-4172-937e-bb78315bdf7a",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDJI_0013.jpg?alt=media&token=4866dee3-e9b8-4eb4-b697-2939bf75865d",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDJI_0030.jpg?alt=media&token=1561c78a-4245-49cd-860c-928344dda397",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDJI_0201.jpg?alt=media&token=76610743-13d4-4f2f-b279-5ed5bc0f99b2",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDJI_0204.jpg?alt=media&token=ac60d3d3-3fca-4149-b5fd-6b089d4883e3",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDJI_0568.jpg?alt=media&token=b27463e1-e5f4-4217-814b-8b4ab1a6d7e6",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDSC_0642.jpg?alt=media&token=fd340f1f-d769-4bd6-8ca4-aef27ade4242",
    },
    {
      image: "https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/home%20img%2FDSC_0959.jpg?alt=media&token=c84684bc-b850-48fe-9ceb-8265f3c9e2de",
    },
  ];