import React, { Component, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { useTranslation } from 'react-i18next';

const CustomNavbar = (props) => {
    const { mClass, nClass, cClass, slogo } = props;
    const [t, i18n] = useTranslation("global");

    const [language, setLanguage] = useState('');

    useEffect(() => {
        // Recuperar el valor almacenado en el local storage al cargar la página
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    const handleInputChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img style={{height: '6vh'}} src={require("../img/logoRT.png")} alt="" />
                            <img style={{height: '6vh'}} src={require("../img/logoRTC.png")} alt="logo" />
                        </Link>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img style={{height: '6vh'}} src={require("../img/logoVP.png")} alt="" />
                            <img style={{height: '6vh'}} src={require("../img/logoVPC.png")} alt="logo" />
                        </Link>
                        <a className={`navbar-brand ${slogo}`} href="https://www.una.ac.cr/" target="_blank" rel='noopener noreferrer'>
                            <img style={{height: '6vh'}} src={require("../img/logoUNA.png")} alt="" />
                            <img style={{height: '6vh'}} src={require("../img/logoUNAC.png")} alt="logo" />
                        </a>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img style={{height: '5vh'}} src={require("../img/destinoAccesible.png")} alt="" />
                            <img style={{height: '5vh'}} src={require("../img/destinoAccesible.png")} alt="logo" />
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item">
                                    <NavLink title={t("global.home")} className="nav-link" to="/inicio">
                                        {t("global.home")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink title={t("global.events")} className="nav-link" to="/eventos">
                                        {t("global.events")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink title={t("global.attractions")} className="nav-link" to="/atracciones">
                                        {t("global.attractions")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink title={t("global.restaurants")} className="nav-link" to="/restaurantes">
                                        {t("global.restaurants")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink title={t("global.lodgings")} className="nav-link" to="/hospedaje">
                                        {t("global.lodgings")}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown submenu">
                                    <a className="nav-link dropdown-toggle" href=".#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {t("global.more")}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><NavLink to="/oficinas-turisticas" className="nav-link">{t("global.touristic_offices")}</NavLink></li>
                                        <li className="nav-item"><NavLink to="/operadores-turisticos" className="nav-link">{t("global.tour_operators")}</NavLink></li>
                                        <li className="nav-item"><NavLink to="/renta-cars" className="nav-link">{t("global.rental_cars")}</NavLink></li>
                                        <li className="nav-item"><NavLink to="/perezzeledon" className="nav-link">{t("global.perez_zeledon")}</NavLink></li>
                                        <li className="nav-item"><NavLink to="/mapas" className="nav-link">{t("global.maps")}</NavLink></li>
                                        <li className="nav-item"><NavLink to="/contacto" className="nav-link">{t("global.contact")}</NavLink></li>
                                        <li className="nav-item"><a target="_blank" rel='noopener noreferrer' href='https://drive.google.com/drive/folders/10OkYsHdGs9-HaJI7FgEdEbjNAOo32gZn?usp=sharing' className="nav-link">{t("global.downloadable_resources")}</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <button onClick={() => handleLanguageChange('es')} className="language-button es" ></button>
                                    <button onClick={() => handleLanguageChange('en')} className="language-button en" ></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </Sticky>
    );
};

export default CustomNavbar;