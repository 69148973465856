import React from 'react'
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

export const Biodiversidad = (props) => {
    let { titulo, creditos } = props

    const { t } = useTranslation('global');

    const getCreditos = () => {
        if(creditos){
            if(creditos.length > 0){
                return <p className='creditos'>{creditos}</p>
            }
        }
    }
    return (
        <section className="seo_features_one">
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-12 mt_70">
                        <Fade bottom cascade>
                            <div className="seo_features_content">
                                <h2>{titulo}</h2>
                                <p className='text-justify'>
                                { t('perez_zeledon.biodiversity_description1') } 
                                    <br/>{ t('perez_zeledon.biodiversity_description2') } 
                                    <br/>{ t('perez_zeledon.biodiversity_description3') } 
                                    <br/>{ t('perez_zeledon.biodiversity_description4') } 
                                    <br/>{ t('perez_zeledon.biodiversity_description5') } 
                                </p>
                                {
                                    getCreditos()
                                }
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}
