import React from 'react';
import { Reveal } from 'react-reveal';
import Sectitle from '../Title/Sectitle';
import AtraccionesCardItemOne from './AtraccionesCardItemOne';
import { useTranslation } from "react-i18next"

const AtraccionesSectionCardsOne = ({ atracciones }) => {

    const { t } = useTranslation("global");

    return (
        <section className="atracciones_section_cards_one_area sec_pad">
            <div className="container">
                <Sectitle sClass="atracciones_section_cards_one_title text-center" Title={t("attractions.perez_zeledón_is_waiting_for_you")} TitleP={t("attractions.hundreds_of_attractions")} />
                <Reveal bottom cascade>
                    <div className="row">
                        {
                            atracciones.map((a, i) => {
                                return (
                                    <div className="col-lg-3 col-sm-6" key={i}>
                                        <AtraccionesCardItemOne img={a.imagenes[0].url} nombre={a.nombre} distrito={a.ubicacion?.distrito} id={a.id} s={a} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Reveal>
            </div>
        </section>
    )
}
export default AtraccionesSectionCardsOne;