import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import { capitalizeFirstLetter, lowerFirstLetter, getFirtsImage, getHrefCall, getTelefono, settingsSlider } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import EventoIndividualSideBar from './EventoIndividualSideBar';

const EventoIndividualContainer =({evento_,})=>{

    const [ evento, setEvento ] = useState()
    const { t } = useTranslation('global')
    const language = localStorage.getItem('language')
    const ESPANOL = 'es';

    useEffect(()=>{
        setEvento(evento_)
    }, [evento_])

    const haveSlider = ()=>{
        if(evento?.imagenes){
            if(evento.imagenes.length > 0){
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <React.Fragment>
                                <Slider {...settingsSlider(1, 1, 1, 1)} className="sitio_single_slider">
                                    {
                                        evento.imagenes.map((img, i)=>{
                                            return (
                                                <div className="iitem" key={i}>
                                                    <div className="sitio_single_item">
                                                        <img src={img.url} alt=""/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </React.Fragment>
                        </div>
                    </div>
                )
            }
        }
    }

    const haveDescription = ()=>{
        let descripcion = language === ESPANOL ? evento?.descripcion : evento?.descripcionIngles;
        if (!descripcion) {
            descripcion = language === ESPANOL ? evento?.descripcionIngles : evento?.descripcion;
        }
        if(descripcion){
            return (
                <p className="f_400 mb-30 text-justify">{descripcion}</p>
            )
        }
    }



    const getContactoIcon = (tipoContacto) => {
        if(tipoContacto === "Página web"){
            return "ti-world"
        }else if(tipoContacto === "Email"){
            return "ti-email"
        }else if(tipoContacto === "Teléfono"){
            return "ti-mobile"
        }else if(tipoContacto === "Facebook"){
            return "ti-facebook"
        }else if(tipoContacto === "Instagram"){
            return "ti-instagram"
        }
    }

    const getContactoTipo = (contacto) => {
        if(contacto.tipo === "Facebook" || contacto.tipo === "Instagram" || contacto.tipo === "Página web"){
            return contacto.tipo
        }else{
            return contacto.valor
        }
    }

    const getContactoHref = (contacto) =>{
        if(contacto.tipo === "Facebook" || contacto.tipo === "Instagram" || contacto.tipo === "Página web"){
            return contacto.valor
        }else{
            if(contacto.tipo === "Email"){
                return "mailto:"+contacto.valor
            }else if(contacto.tipo === "Teléfono"){
                return getHrefCall(contacto.valor)
            }
        }
    }

    const haveContactos = () => {
        if(evento?.contactos){
            if(evento.contactos.length > 0){
                return (
                    <div className="post_share mb-30">
                        {
                            evento.contactos.map((c, i)=>{
                                return <a target="_new" href={getContactoHref(c)} key={i}><i className={getContactoIcon(c.tipo)}></i>{getContactoTipo(c)}</a>
                            })
                        }
                    </div>
                )
            }
        }
    }

    const haveUbicacion = () => {
        if(evento?.ubicacion){
            return (
                <>
                    <div className="post-nam">
                        <a ><i className="icon_pin_alt"></i>{ t('global.location') }</a>
                    </div>
                    <p className="f_400 mt_05">{`${evento.ubicacion.provincia}, ${evento.ubicacion.canton}, ${evento.ubicacion.distrito}, ${evento.ubicacion.detalle || ""}`}</p>
                </>
                
            )
        }
    }

    const getDate = () => {
        if(evento?.fechaInicio === evento?.fechaFin){
            let fechaSlice = evento?.fechaInicio.slice(0,10)
            let fechaSplit = fechaSlice.split('-')
            return ` ${fechaSplit[2]}/${fechaSplit[1]}/${fechaSplit[0]}` 
        }
        let fechaInicioSlice = evento?.fechaInicio.slice(0,10)
        let fechaInicioSplit = fechaInicioSlice.split('-')
        let fechaFinSlice = evento?.fechaFin.slice(0,10)
        let fechaFinSplit = fechaFinSlice.split('-')
        return ` ${fechaInicioSplit[2]}/${fechaInicioSplit[1]}/${fechaInicioSplit[0]} - ${fechaFinSplit[2]}/${fechaFinSplit[1]}/${fechaFinSplit[0]}` 
    }


    const haveFechas = () => {
        if(evento?.fechaInicio){
            return (
                <>
                    <div className="post-nam">
                        <a ><i className="icon_calendar"></i>{language === ESPANOL ? 'Fecha' : 'Date'}</a>
                    </div>
                    <p className="f_400 mt_05">{getDate()}</p>
                </>
                
            )
        }
    }
    
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                {
                    haveSlider()
                }
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <div className="blog_content">
                                <div className="entry_post_info">
                                    {/* <a>{getTipoTraducido(tipo)}</a> */}
                                </div>
                                <a>
                                    <h5 className="f_p f_size_20 f_500 t_color mb_15">{language === ESPANOL ? evento?.nombre : evento?.nombreIngles}</h5>
                                </a>
                                {
                                    haveDescription()
                                }
                                {
                                    haveContactos()
                                }
                                {
                                    haveUbicacion()
                                }
                                {
                                    haveFechas()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <EventoIndividualSideBar atracciones_={evento?.atracciones} restaurantes_={evento?.restaurantes} hoteles_={evento?.hoteles} oficinas_={evento?.oficinas} operadores_={evento?.operadores} rentadoras_={evento?.rentadoras} />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EventoIndividualContainer;