import React, {Component} from 'react';

class AtraccionesActionOne extends Component {
    render(){
        let {aClass, aimage, colClass, colClassTwo, Atitle, Atext, btnText}= this.props;
        return(
            <section className={`${aClass}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className={`${colClass}`}>
                            <div className="h_action_img">
                                <img className="img-fluid" src={require ("../../img/atracciones/" + aimage)} alt=""/>
                            </div>
                        </div>
                        <div className={`${colClassTwo}`}>
                            <div className="h_action_content">
                                <h2>{Atitle}</h2>
                                <p>{Atext}</p>
                                <a href="/buscar/atracciones/Todos/Todos/1/Todos/Todos" className="hosting_btn btn_hover">{`${btnText}`}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default AtraccionesActionOne;