import React from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from "react-i18next"

const HomeBienvenida = () => {
    
    const [t, i18n] = useTranslation("global");

    return (
        <section className="event_about_area">
            <div className="container">
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="event_about_img">
                            <ReactPlayer
                                url='https://youtu.be/YETNzIqQ6eU'
                                playing={true}
                                muted={true}
                                controls
                                volume={0.5}
                                width="100%"
                            >
                            </ReactPlayer>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="event_about_content">
                            <h2 className="wow fadeInUp">{t("home.welcome")}</h2>
                            <p className="wow fadeInUp" data-wow-delay="0.2s">{ t('home.welcome_description') }</p>
                            <div className="row">
                                <div className="col">
                                    <div className="event_about_item wow fadeInUp" data-wow-delay="0.2s">
                                        <a href="/buscar/atracciones/Todos/Todos/1/Todos/Todos" className="hosting_btn btn_hover text-center" style={{ width: "100%" }}>{ t('home.discover_perez') }</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeBienvenida;