import React, {Component} from 'react';
import Slider from 'react-slick/';
import { settingsSlider } from '../../helpers/helpers';

class RestaurantesCardSlider extends Component {
    render() {
        let { restaurantes } = this.props
        
        const getUbicacion = (sitio)=>{
            if(sitio.ubicacion){
                if(sitio.ubicacion.detalle){
                    return <p><a href={`/sitio/restaurantes/${sitio.id}`}>{`${sitio.ubicacion?.distrito}, ${sitio.ubicacion?.detalle}`}</a></p>
                }
                return <p><a href={`/sitio/restaurantes/${sitio.id}`}>{`${sitio.ubicacion?.distrito}`}</a></p>
            }
        }

        return (
            <React.Fragment>
                <Slider {...settingsSlider(3, 3, 2, 1)} className="case_studies_slider">
                    {
                        restaurantes.map((r, i)=>{
                            return (
                                <div className="iitem" key={i}>
                                    <div className="studies_item">
                                        <img src={r.imagenes[0].url} alt=""/>
                                        <div className="text">
                                            <a href={`/sitio/restaurantes/${r.id}`}>
                                                <h4>{r.nombre}</h4>
                                            </a>
                                            {
                                              getUbicacion(r)
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* <div className="iitem">
                        <div className="studies_item">
                            <img src="https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/images%2Frestaurantes%2F16-1636757383933.jpg?alt=media&token=5fc5fd1c-dd63-419a-808b-2474f8508637" alt=""/>
                            <div className="text">
                                <a href=".#">
                                    <h4>El Asador</h4>
                                </a>
                                <p><a href=".#">El General, General Viejo</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="iitem">
                        <div className="studies_item">
                            <img src="https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/images%2Frestaurantes%2F14-1636757038848.jpg?alt=media&token=81ea0ab8-554f-49c0-bdf2-3d1e20b3baaf" alt=""/>
                            <div className="text">
                                <a href=".#">
                                    <h4>Próxima parada helado y café</h4>
                                </a>
                                <p><a href=".#">El General, La hermosa, 75m Norte de la Escuela La Hermosa</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="iitem">
                        <div className="studies_item">
                            <img src="https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/images%2Frestaurantes%2F11-1636756125010.jpg?alt=media&token=36539080-9598-4e16-823a-2b79e8104fc6" alt=""/>
                            <div className="text">
                                <a href=".#">
                                    <h4>Valle Encantado</h4>
                                </a>
                                <p><a href=".#">Barú, 12km del centro de san isidro Pérez Zeledón camino a dominical</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="iitem">
                        <div className="studies_item">
                            <img src="https://firebasestorage.googleapis.com/v0/b/rutas-turisticas-admin.appspot.com/o/images%2Frestaurantes%2F46-1636766953644.jpg?alt=media&token=45239ad1-4ce8-4d79-bc99-b149dbaeab62" alt=""/>
                            <div className="text">
                                <a href=".#">
                                    <h4>Restaurante Batsú</h4>
                                </a>
                                <p><a href=".#">Canaán</a></p>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </React.Fragment>
        )
    }
}

export default RestaurantesCardSlider;