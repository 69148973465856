import React, {Component} from 'react';
import Slider from 'react-slick/';
import { settingsSlider } from '../../helpers/helpers';

class OperadoresTuristicosCardSlider extends Component {
    render() {
        let { operadores } = this.props

        const getUbicacion = (sitio)=>{
            if(sitio.ubicacion){
              if(sitio.ubicacion.detalle){
                  return <p><a href={`/sitio/operadoresTuristicos/${sitio.id}`}>{`${sitio.ubicacion?.distrito}, ${sitio.ubicacion?.detalle}`}</a></p>
              }
              return <p><a href={`/sitio/operadoresTuristicos/${sitio.id}`}>{`${sitio.ubicacion?.distrito}`}</a></p>
            }
        }
        return (
            <React.Fragment>
                <Slider {...settingsSlider(3, 3, 2, 1)} className="case_studies_slider">
                    {
                        operadores.map((o, i)=>{
                            return (
                                <div className="iitem" key={i}>
                                    <div className="studies_item">
                                        <img src={o.imagenes[0].url} alt=""/>
                                        <div className="text">
                                            <a href={`/sitio/operadoresTuristicos/${o.id}`}>
                                                <h4>{o.nombre}</h4>
                                            </a>
                                            {
                                              getUbicacion(o)
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </React.Fragment>
        )
    }
}

export default OperadoresTuristicosCardSlider;