import React, { useState } from 'react';
import SeoTitle from '../Title/SeoTitle';
import { useHistory } from 'react-router-dom';
import { validateText } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

export const RestaurantesSearch = () => {

    const [ filtro, setFiltro ] = useState("")
    const history = useHistory();
    const { t } = useTranslation('global');

    
    const handleSearch = (e) => {
        let f = "Todos"
        
        if(filtro.length > 1){
            f = filtro
        }
        history.push(`/buscar/restaurantes/${f}/Todos/1/Todos/Todos`);
        
    }
    
    return(
        <React.Fragment>
            <section className="restaurante_search_area">
                <div className="overlay_img"></div>
                <div className="container">
                    <SeoTitle Title={ t('restaurants.find_the_perfect_restaurant_for_you') }/>
                    <form className="row restaurante_search_form">
                        <div className="input-group col-lg-9 col-md-8 col-sm-6">
                            <input type="text" name="website" id="website" value={filtro} onKeyDown={(e) => {if(e.key === "Enter"){handleSearch(e)}else{validateText(e, /[^a-zA-Z0-9, ñ]/g)}}} onChange={(e)=>setFiltro(e.target.value)} placeholder={ t('restaurants.name_of_the_restaurant') } className="form-control" maxLength="60" />
                        </div>
                        <div className="input-group col-lg-3 col-md-4 col-sm-6">
                            <input type="button" onClick={handleSearch} value={ t('restaurants.search') } className="check-btn"/>
                        </div>
                    </form>
                </div>
            </section>
        </React.Fragment>
    )
}


export default RestaurantesSearch;