import React, { useEffect, useState } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterData from '../components/Footer/FooterData';
import SitioIndividualBanner from '../components/Banner/SitioIndividualBanner';
import SitioIndividualContainer from '../components/Sitios/SitioIndividualContainer';
import FooterRutas from '../components/Footer/FooterRutas';
import { getAtraccionesById, getAtraccionesWithUbicacion } from '../services/atracciones';
import { useParams } from "react-router-dom";
import { getRestaurantesById, getRestaurantesWithUbicacion } from '../services/restaurantes';
import { getHotelesById, getHotelesWithUbicacion } from '../services/hospedaje';
import { getOperadoresById, getOperadoresWithUbicacion } from '../services/operadoresTuristicos';
import { getOficinasById, getOficinasDifferent } from '../services/oficinas';
import { getRentadorasById, getRentadorasDifferent } from '../services/rentadoras';
  

const SitioIndividual = () => {
    let { tipo, id } = useParams()
    // const tipo = props.match.params.tipo;
    // const id = props.match.params.id;
    const [ sitio, setSitio ] = useState()
    const [ lugaresCercanos, setLugaresCercanos ] = useState([])

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])


    useEffect(()=>{
        if(tipo === "atracciones"){
            getAtraccionesById(id).then(
                res => {
                    if(res.data){
                        setSitio(res.data)
                        if(res.data.ubicacion && res.data.ubicacion.provincia && res.data.ubicacion.canton && res.data.ubicacion.distrito)
                        {
                            getAtraccionesWithUbicacion(res.data.nombre, 0, 3, res.data.ubicacion.provincia, res.data.ubicacion.canton, res.data.ubicacion.distrito).then(
                                res2 => {
                                    if(res2.data.atracciones){
                                        setLugaresCercanos(res2.data.atracciones)
                                    }
                                }
                            )
                        }
                    }
                }
            )
        }else if(tipo === "restaurantes"){
            getRestaurantesById(id).then(
                res=>{
                    if(res.data){
                        setSitio(res.data)
                        if(res.data.ubicacion && res.data.ubicacion.provincia && res.data.ubicacion.canton && res.data.ubicacion.distrito)
                        {
                            getRestaurantesWithUbicacion(res.data.nombre, 0, 3, res.data.ubicacion.provincia, res.data.ubicacion.canton, res.data.ubicacion.distrito).then(
                                res2 => {
                                    if(res2.data.restaurantes){
                                        setLugaresCercanos(res2.data.restaurantes)
                                    }
                                }
                            )
                        }
                    }
                }
            )
        }else if(tipo === "hospedaje"){
            getHotelesById(id).then(
                res=>{
                    if(res.data){
                        setSitio(res.data)
                        if(res.data.ubicacion && res.data.ubicacion.provincia && res.data.ubicacion.canton && res.data.ubicacion.distrito)
                        {
                            getHotelesWithUbicacion(res.data.nombre, 0, 3, res.data.ubicacion.provincia, res.data.ubicacion.canton, res.data.ubicacion.distrito).then(
                                res2 => {
                                    if(res2.data.hoteles){
                                        setLugaresCercanos(res2.data.hoteles)
                                    }
                                }
                            )
                        }
                    }
                }
            )
        }else if(tipo === "operadoresTuristicos"){
            getOperadoresById(id).then(
                res=>{
                    if(res.data){
                        setSitio(res.data)
                        if(res.data.ubicacion && res.data.ubicacion.provincia && res.data.ubicacion.canton && res.data.ubicacion.distrito)
                        {
                            getOperadoresWithUbicacion(res.data.nombre, 0, 3, res.data.ubicacion.provincia, res.data.ubicacion.canton, res.data.ubicacion.distrito).then(
                                res2 => {
                                    if(res2.data.operadores){
                                        setLugaresCercanos(res2.data.operadores)
                                    }
                                }
                            )
                        }
                    }
                }
            )
        }else if(tipo === "oficinasTuristicas"){
            getOficinasById(id).then(
                res=>{
                    if(res.data){
                        setSitio(res.data)
                        getOficinasDifferent(res.data.nombre, 0, 6).then(
                            res2 => {
                                if(res2.data.oficinas){
                                    setLugaresCercanos(res2.data.oficinas)
                                }
                            }
                        )
                        
                    }
                }
            )
        }else if(tipo === "rentacars"){
            getRentadorasById(id).then(
                res=>{
                    if(res.data){
                        setSitio(res.data)
                        getRentadorasDifferent(res.data.nombre, 0, 6).then(
                            res2 => {
                                if(res2.data.rentadoras){
                                    setLugaresCercanos(res2.data.rentadoras)
                                }
                            }
                        )
                        
                    }
                }
            )
        }
    }, [tipo, id])
    
    const getBannerImg = () => {
        if(sitio && sitio.imagenes){
            if(sitio.imagenes.length > 0){
                return sitio.imagenes[0].url
            }else{
                return "defecto"
            }
        }else{
            return "defecto"
        }
    }

    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" slogo="sticky_logo" nClass="w_menu custom_container p0"/>
            <SitioIndividualBanner breadcrumbClass="breadcrumb_area" imgName={getBannerImg()} Ptitle={sitio?.nombre} Pdescription=""/>
            <SitioIndividualContainer sitio_={sitio} tipo_={tipo} lugaresCercanos_={lugaresCercanos}/>
            <FooterRutas FooterData={FooterData}/>
        </div>
    )
}
export default SitioIndividual;