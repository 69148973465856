import React from 'react';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterRutas = ({ FooterData, fClass }) => {

    const [t, i18n] = useTranslation("global");
    const language = localStorage.getItem('language');
    const ESPANOL = 'es';

    return (
        <footer className={`footer_area footer_area_four h_footer_dark  ${fClass}`}>
            <div className='footer_top'>
                <div className="container">
                    <div className="row">
                        {
                            FooterData.CompanyWidget.map((widget, i) => {
                                return (
                                    <Reveal effect="fadeInLeft" duration={500} key={i}>
                                        <div className="col-lg-3 col-sm-12" >
                                            <div className="f_widget dark_widget company_widget" data-wow-delay="0.2s">
                                                <div className="d-flex justify-content-start">
                                                    <a href="/" className="f-logo">
                                                        <img style={{height: '6vh'}} src={require("../../img/logoRT.png")} alt="" />
                                                    </a>
                                                    <a href="/" className="f-logo">
                                                        <img style={{height: '6vh'}} src={require("../../img/logoVP.png")} alt="" />
                                                    </a>
                                                    <a href="/" className="f-logo">
                                                        <img style={{height: '6vh'}} src={require("../../img/logoUNA.png")} alt="" />
                                                    </a>
                                                    <a href="/" className="f-logo">
                                                        <img style={{height: '5vh'}} src={require("../../img/destinoAccesible.png")} alt="" />
                                                    </a>
                                                </div>
                                                {/* <a href="/" className="f-logo"><img src={require("../../img/logort1.1.png")} alt="" /></a> */}
                                                <div className="widget-wrap">
                                                    <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Email:</span> <a href="mailto:erick.madrigal.villanueva@una.ac.cr" className="f_400">erick.madrigal.villanueva@una.ac.cr</a></p>
                                                    <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Tel:</span> <a href="tel:50688388535" className="f_400">(506) 88388535</a></p>
                                                    <a href='/contacto'>
                                                        <button className='btn_hover'>{ t('footer.i_want_to_be_on_the_page') }</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }
                        {
                            FooterData.AboutWidget.map((widget, i) => {
                                return (
                                    <Reveal effect="fadeInLeft" duration={500} key={i}>
                                        <div className="col-lg-3 col-sm-12">
                                            <div className="f_widget dark_widget about-widget pl_70">
                                                <h3 className="f-title f_500 t_color f_size_18 mb_40">{language === ESPANOL ? widget.title : widget.titleEN}</h3>
                                                <ul className="list-unstyled f_list">
                                                    {
                                                        widget.menuItems.map(item => {
                                                            return (
                                                                <li key={item.id}><Link to={item.url}>{language === ESPANOL ? item.text : item.textEN}</Link></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }

                    </div>
                    <div className='row'>
                        <div className='col-lg-12 pt_120 text-center'>
                            <p className='f_size_12 text-justify'>{ t('footer.footer_description') }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <p className="mb-0 f_400">{language === ESPANOL ? FooterData.copywrite : FooterData.copywriteEN}</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="f_social_icon_two text-right">
                                <p>{ t('footer.developed_with_the') } <i className="icon_heart"></i> { t('footer.by') } <a href='https://www.linkedin.com/in/pablove00/' target="_blank" rel='noopener noreferrer'>Pablo Venegas</a> y <a href='https://www.linkedin.com/in/luis-miguel-valverde-navarro-b1816b213/' target="_blank" rel='noopener noreferrer'>Luis Valverde</a>.</p>
                                <p>{ t('footer.academic') }: <a href='https://orcid.org/0000-0003-4317-3422' target="_blank" rel='noopener noreferrer'>Mag. Erick Madrigal Villanueva</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}
export default FooterRutas;