import React from 'react'
import { MapaItem } from './MapaItem';

export const Mapas = () => {

    return(
        <section className="contact_info_area mb_60 bg_color">
            <div className="container">
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Cataratas y Pozas" img="cataratas.PNG" url="https://drive.google.com/file/d/1NuSq7ajp0I4N3emfvqs60_OQHcZYiTtO/view?usp=sharing" />
                    <MapaItem titulo="Agroturismo" img="agroturismo.PNG" url="https://drive.google.com/file/d/1Wec5dK0GanaMXdvw8MlUZTvOTpP7djez/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Aventura" img="aventura.PNG" url="https://drive.google.com/file/d/1NzvH2lIa1ywxRNc1DuCMLth8Mla4jD5f/view?usp=sharing" />
                    <MapaItem titulo="Trucheros y Tilaperos" img="trucheros.PNG" url="https://drive.google.com/file/d/1LAbSdsOweOT225ABOia1NCL9XLaB9RRi/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Cerros, Miradores y Reservas Naturales" img="CerrosMiradoresYReservas.PNG" url="https://drive.google.com/file/d/1WjmCuSN_C2Vjrpk-CyA4Cem0EqmwNW15/view?usp=sharing" />
                    <MapaItem titulo="San Isidro" img="sanisidro.PNG" url="https://drive.google.com/file/d/1lnrl7OIK98kSoxf_LpSqfnfPrXSQkue-/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Rivas" img="rivas.PNG" url="https://drive.google.com/file/d/1QU8B30xAecYlHYZzAEn2fJifcbqTpbts/view?usp=sharing" />
                    <MapaItem titulo="Baru" img="baru.PNG" url="https://drive.google.com/file/d/1RjKvpuxiY3NQWGLs3srwCZN-Ui6gLp_o/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="San Pedro" img="sanpedro.PNG" url="https://drive.google.com/file/d/1NCLO5NFZU0zcH_BedjXmeTnYQ6lJBER0/view?usp=sharing" />
                    <MapaItem titulo="Río Nuevo" img="rionuevo.PNG" url="https://drive.google.com/file/d/1yEcAoLgrw9-GqWhsNHVapbheFyBppZHN/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Páramo" img="paramo.PNG" url="https://drive.google.com/file/d/1qvXC0mcNcTcYpoHdjSbdnaWTCpgtd0ni/view?usp=sharing" />
                    <MapaItem titulo="Pejibaye" img="pejibaye.PNG" url="https://drive.google.com/file/d/1dKSqBtVK-ndWPj8RpiAmWybUc4KY7uuj/view?usp=sharing" />
                </div>
                <div className='mapas_featured_item row'>
                    <MapaItem titulo="Platanares" img="platanares.PNG" url="https://drive.google.com/file/d/1mB-muNDBFNYcEPLs98djEp0ZKqatNYjd/view?usp=sharing" />
                    <MapaItem titulo="Cordillera de Talamanca" img="cordillera.PNG" url="https://drive.google.com/file/d/1DGy9yDCCFeA4OYqFfTahUJBJ3YCQEfSM/view?usp=sharing" />
                </div>
            </div>
        </section>
    )
}

export default Mapas;